import { useState, useEffect } from "react";
import { Card, Form, Button, message, Select } from "antd";
import { ExpertService, SessionService } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { FieldTimeOutlined } from "@ant-design/icons";
import HourUpdateModal from "./HourUpdateModal";
import {
  setSessionModals,
  setSessionDetail,
} from "../../../redux/sessionSlice";
import useSlots from "../../../hooks/useSlots";
import useQuery from "../../../hooks/useQuery";
import { updateSessionTime } from "../../../../services";
import MeetingTypeUpdate from "./MeetingTypeUpdate";
const hourLabel = (labelName, slotList) => {
  const reservable = slotList?.find((item) => item.label === labelName);
  if (!reservable) {
    return (
      <span className="text-danger">
        <FieldTimeOutlined />

        {labelName}
      </span>
    );
  }
  return (
    <>
      <FieldTimeOutlined />
      {labelName}
    </>
  );
};
const SessionForm = () => {
  const dispatch = useDispatch();
  const { queryData, queryLoading } = useQuery({
    service: ExpertService.getExpert,
    params: {},
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const sessionState = useSelector((state) => state.session.sessionDetail);
  const slotState = useSelector((state) => state.session.slot);

  const [form] = Form.useForm();
  const { expertId } = form.getFieldsValue();
  const [getExpertAvailabilities, loadingSlot] = useSlots();

  const expertList = () => {
    return queryData?.experts?.map((item) => ({
      value: item.expertId,
      label: (
        <div className="d-flex align-items-center">
          <img
            className="mr-2"
            src={item.profilePictureUrl}
            alt=""
            width={30}
          />
          {item.fullName}
        </div>
      ),
      searchExpert: item.fullName,
    }));
  };
  const onFinish = async () => {
    setLoadingSubmit(true);
    try {
      const body = {
        expertId,
        updatedSlotId: slotState.slotId,
        selectedSlots: [`S${sessionState.hourInfo.split(":").join("")}`],
      };
      const response = await SessionService.reassignSession(
        body,
        sessionState.sessionId
      );
      const firebaseResponse = await updateSessionTime(
        sessionState.sessionId,
        sessionState?.sessionCurrentTime || sessionState.sessionTime
      );
      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }
      message.success("İşlem Başarılı.");
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    getExpertAvailabilities();
  }, []);

  return (
    <>
      <MeetingTypeUpdate
        meetingType={sessionState.meetingType}
        sessionId={sessionState.sessionId}
      />
      <Card loading={queryLoading} className="mb-3 col-12 col-md-12 col-lg-12">
        <Form onFinish={onFinish} form={form} layout="vertical">
          <div className="row">
            <Form.Item
              label="Uzman Değiştir"
              className="col-12 col-md-6 col-lg-3"
              initialValue={sessionState.expertId}
              name={"expertId"}
            >
              <Select
                optionFilterProp="searchExpert"
                disabled={queryLoading}
                loading={queryLoading}
                showSearch
                allowClear
                name={"expertId"}
                placeholder="Seans Uzmanını Seçiniz"
                options={expertList()}
                size="large"
                onChange={(value) => {
                  getExpertAvailabilities({ expertId: value });
                  dispatch(
                    setSessionDetail({ ...sessionState, expertId: value })
                  );
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item
              label="Saati Düzenle"
              className="mb-3 col-12 col-md-6 col-lg-3"
            >
              <Button
                className="w-100"
                disabled={loadingSlot}
                loading={loadingSlot}
                onClick={() => {
                  dispatch(setSessionModals({ sessionHourUpdate: true }));
                }}
                size="large"
                style={{ justifyContent: "flex-start" }}
              >
                {hourLabel(sessionState.hourInfo, slotState.slotHours)}
              </Button>
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              loading={loadingSubmit}
              disabled={loadingSubmit}
              type="primary"
              className="w-100"
              size="large"
              htmlType="submit"
            >
              Düzenle
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <HourUpdateModal />
    </>
  );
};
export default SessionForm;
