import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationDetail: {},
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notificationDetail = action.payload;
    },
    resetNotificaiton: (state) => {
      state = initialState;
    },
  },
});

export const { addNotification, resetNotificaiton } = notificationSlice.actions;

export default notificationSlice.reducer;
