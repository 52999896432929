import React, { useState } from "react";
import { Form, Input, message, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../api";
import { LockOutlined } from "@ant-design/icons";
export default function RenewPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await AuthService.renewPassowrd(values);
      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="row justify-content-center  align-items-center h-80">
      <div className="col-md-6">
        <div className="authincation-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <h4 className="text-center mb-4 ">
                  {" "}
                  Telefonunuza gelen kod ve yeni şifrenizi Yazınız.{" "}
                </h4>
                <Form
                  scrollToFirstError
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    name="newPassword"
                    label="Yeni Şifre"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Şifre alanını doldurun.",
                      },
                      { type: "password" },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="mr-2" />}
                      type="password"
                      name="newPassword"
                      placeholder="Yeni Şifre"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="code"
                    label="Kod"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Email alanını doldurun.",
                      },
                      { type: "email", message: "Lütfen email Yazınız." },
                    ]}
                  >
                    <Input
                      type="text"
                      name="code"
                      placeholder="4561"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      className="w-100"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                    >
                      Şifremi Yenile{" "}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
