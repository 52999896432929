import { initializeApp } from "firebase/app";
import { doc, getFirestore, updateDoc } from "firebase/firestore/lite";

const firebaseConfig = {
  apiKey: "AIzaSyBbpJKoKyR7LKHPHZdbqadsO1krJSbT1xA",
  authDomain: "healmeup-app-4e6a8.firebaseapp.com",
  projectId: "healmeup-app-4e6a8",
  storageBucket: "healmeup-app-4e6a8.appspot.com",
  messagingSenderId: "195005501436",
  appId: "1:195005501436:web:cc80b355610127e32fac79",
  measurementId: "G-8TPPZN02G5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const COLLECTION_NAME = "test_sessions";
const COLLECTION_NAME = "sessions";

export async function updateSessionTime(sessionId, sessionTime) {
  try {
    if (!sessionId) return;
    const refDoc = doc(db, COLLECTION_NAME, sessionId);
    await updateDoc(refDoc, {
      sessionTime,
    });
  } catch (error) {
    console.log(error);
  }
}
export async function sessionEndTarget(sessionId) {
  try {
    if (!sessionId) return;
    const refDoc = doc(db, COLLECTION_NAME, sessionId);
    await updateDoc(refDoc, {
      isSessionEnd: true,
    });
  } catch (error) {
    console.log(error);
  }
}
