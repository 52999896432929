import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Table
import AgGridTable from "../../components/AgGridTable";

// Expert Service
import { ExpertService } from "../../../api";
import { Button } from "antd";
import { useDispatch } from "react-redux";

import { resetExpertState } from "../../redux/newExpertSlice";

import { PlusOutlined } from "@ant-design/icons";

import useQuery from "../../hooks/useQuery";
import { expertsListColumn } from "../../components/Columns";

const Experts = () => {
  const { queryLoading, queryData } = useQuery({
    service: ExpertService.getExpert,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onRowClick = (params) => {
    const pushUrl = "/expert-detail/" + params.data.expertId;
    navigate(pushUrl);
  };

  return (
    <AgGridTable
      loading={queryLoading}
      onRowClicked={onRowClick}
      rowData={queryData?.experts}
      columnDefs={expertsListColumn}
      sideBar={"filters"}
      rowHeight={40}
      headerChildren={
        <Button
          onClick={() => {
            dispatch(resetExpertState());
            navigate("/new-expert");
          }}
          type="primary"
        >
          <PlusOutlined />
          Yeni Uzman Ekle
        </Button>
      }
    />
  );
};

export default Experts;
