import React, { useEffect, useState } from "react";
import CategoryForm from "../../components/Forms/Category";
import DetailHeader from "../../components/DetailHeader";
import { CategoryService } from "../../../api";
import { useParams } from "react-router-dom";
import { message } from "antd";
export default function CategoryDetail() {
  const { categoryId } = useParams();
  const [categoryDetail, setCategoryDetail] = useState();
  const getCategoryDetail = async () => {
    try {
      const response = await CategoryService.getCategories();
      const findCategory = response.data.data.contentCategories.find(
        (item) => item.categoryId === categoryId
      );
      if (!findCategory) {
        message.error("Kategori bulunamadı!!");
      } else {
        setCategoryDetail(findCategory);
      }
    } catch (error) {
      message.error(error.msgDetail || error);
    }
  };
  useEffect(() => {
    getCategoryDetail();
  }, []);
  return (
    <>
      <DetailHeader />
      {categoryDetail ? (
        <CategoryForm action={"update"} detailData={categoryDetail} />
      ) : null}
    </>
  );
}
