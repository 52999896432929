import React, { useState, useCallback, useEffect } from "react";
import AgGridTable from "../../components/AgGridTable";
import { CreditService } from "../../../api";
import { message } from "antd";
import dayjs from "dayjs";
import constants from "../../../constants";
import { useNavigate } from "react-router-dom";
const Credits = () => {
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "expertProfilePictureUrl",
      headerName: "Uzman Fotoğraf",
      cellRenderer: (params) => (
        <img
          style={{
            width: 40,
            height: 40,
            objectFit: "cover",
            borderRadius: "100%",
          }}
          src={params.data.expertProfilePictureUrl}
        />
      ),
      filter: false,
    },
    { field: "sessionCreditId", headerName: "ID" },
    { field: "expertId", headerName: "Uzman ID" },
    { field: "userId", headerName: "Kullanıcı ID" },
    { field: "expertFullName", headerName: "Uzman İsim", minWidth: 200 },
    { field: "userFullName", headerName: "Kullanıcı İsim", minWidth: 200 },
    { field: "expertTitle", headerName: "Uzman Alanı", minWidth: 200 },

    {
      field: "remainingSessionCount",
      headerName: "Seans Kredi",
      minWidth: 150,
    },
    { field: "totalSessionCount", headerName: "Toplam Seans", minWidth: 150 },
    {
      field: "remainingSessionCount",
      headerName: "Kalan Kredi",
      minWidth: 150,
    },
    {
      field: "purchaseDate",
      headerName: "Satın Alım Tarihi",
      minWidth: 150,
      cellRenderer: (params) => {
        return dayjs(params.data.purchaseDate).format("DD-MM-YY HH:mm");
      },
    },
    {
      field: "sessionType",
      headerName: "Seans Tipi",
      cellRenderer: (params) => {
        return constants.sessionTypes[params.data.sessionType];
      },
    },
    {
      field: "sessionPackageOption",
      headerName: "Seans Paketi",
      minWidth: 130,
      cellRenderer: (params) => {
        return constants.sessionPackageOptions[
          params.data.sessionPackageOption
        ];
      },
    },
    {
      field: "expirationDate",
      headerName: "Son Kullanma Tarihi",
      minWidth: 150,
      cellRenderer: (params) => {
        return dayjs(params.data.expirationDate).format("DD-MM-YY HH:mm");
      },
    },
    {
      field: "availability",
      headerName: "Kullanılabilirlik",
      minWidth: 130,
      cellRenderer: (params) => {
        return constants.sessionCreditAvailabilityStatus[
          params.data.availability
        ];
      },
    },
  ]);

  const getCredits = async () => {
    try {
      const response = await CreditService.getCredits({
        startDate: "2023-01-01",
      });
      const credits = response?.data?.data?.sessionCredits;
      if (credits) {
        setRowData(credits);
      }
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onRowClicked = (row) => {
    navigate(`/credit-detail/${row.data.sessionCreditId}`);
  };
  useEffect(() => {
    getCredits();
  }, []);

  return (
    <AgGridTable
      onRowClicked={onRowClicked}
      loading={loading}
      rowData={rowData}
      columnDefs={columnDefs}
      sideBar={"filters"}
      rowHeight={40}
    />
  );
};

export default Credits;
