import axiosInstance from "./axiosConfig";
const api = {
  login: (body) => {
    return axiosInstance.post("/v1/admin/auth/login", body);
  },
  forgotPassword: (body) => {
    return axiosInstance.post("/v1/auth/forgot-password", body);
  },
  renewPassowrd: (body) => {
    return axiosInstance.post("/v1/renew-password", body);
  },
};
export default api;
