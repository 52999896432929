import axiosInstance from "./axiosConfig";
const api = {
  getNotifications: () => {
    const params = {
      size: 100000000,
    };
    return axiosInstance.get("/v1/notifications/mobile-templates", {
      params,
    });
  },
  newNotificaiton: (body) => {
    return axiosInstance.post("/v1/notifications/mobile-templates", body);
  },
  updateNotificaiton: (id, body) => {
    const url = "/v1/notifications/mobile-templates/" + id;
    return axiosInstance.put(url, body);
  },
};
export default api;
