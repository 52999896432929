import React, { useState, useCallback, useEffect } from "react";
import AgGridTable from "../../components/AgGridTable";
import { GroupSessionsService } from "../../../api";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import constants from "../../../constants";
import { groupSessionsColumn } from "../../components/Columns";
import { PlusOutlined } from "@ant-design/icons";
const GroupSessions = () => {
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getGroupSessions = async () => {
    try {
      const response = await GroupSessionsService.getGroupSessions();
      const groupSessions = response?.data?.data?.groupSessions;
      setRowData(groupSessions);
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGroupSessions();
  }, []);

  return (
    <AgGridTable
      loading={loading}
      rowData={rowData}
      columnDefs={groupSessionsColumn}
      sideBar={"filters"}
      headerChildren={
        <Button type="primary" onClick={() => navigate("/new-group-session")}>
          <PlusOutlined />
          Yeni Grup Seansı Ekle
        </Button>
      }
    />
  );
};

export default GroupSessions;
