import axiosInstance from "./axiosConfig";
const api = {
  getPromotions: () => {
    const params = {
      size: 100000000,
    };
    return axiosInstance.get("/v1/promotions", {
      params,
    });
  },
  createPromotion: (body) => {
    return axiosInstance.post("/v1/promotions", body);
  },
  statusUpdatePromotion: (promotionId, status) => {
    return axiosInstance.put(`/v1/promotions/${promotionId}`, {
      updatedStatus: status,
    });
  },
};
export default api;
