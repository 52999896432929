import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessionDetail: {},
  modals: {
    sessionHourUpdate: false,
  },
  slot: {},
  sensUpdateBody: {},
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionDetail: (state, action) => {
      state.sessionDetail = action.payload;
    },
    setSessionModals: (state, action) => {
      const obj = Object.keys(action.payload)[0];
      state.modals[obj] = action.payload[obj];
    },
    setSlot: (state, action) => {
      state.slot = action.payload;
    },
    setUpdateBody: (state, action) => {
      state.sensUpdateBody = action.payload;
    },
    resetSession: (state) => {
      state = initialState;
    },
  },
});

export const {
  setSessionDetail,
  resetSession,
  setSessionModals,
  setSlot,
  setUpdateBody,
} = sessionSlice.actions;

export default sessionSlice.reducer;
