import React from "react";
import { DetailHeader } from "../../components";
import { Tabs } from "antd";
import UserProfile from "./UserProfile";
import UserSubsDetail from "./UserSubsDetail";
export default function UserDetail() {
  return (
    <>
      <DetailHeader />
      <Tabs
        id="user-detail"
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Detay",
            children: <UserProfile />,
          },
          {
            key: "2",
            label: "Abonelikler",
            children: <UserSubsDetail />,
          },
        ]}
      />
    </>
  );
}
