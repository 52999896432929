import { DetailHeader } from "../../components";
import NotificationForm from "../../components/Forms/Notification/";
const NewNotification = () => {
  return (
    <>
      <DetailHeader />
      <NotificationForm action="create" />
    </>
  );
};
export default NewNotification;
