import axiosInstance from "./axiosConfig";
const api = {
  getDashboards: (dates) => {
    return axiosInstance.get(`/v1/dashboard`, {
      params: {
        ...dates,
      },
    });
  },
};
export default api;
