import { useState, useEffect } from "react";
import { Card, Form, Button, message, Select, Input } from "antd";
import { ExpertService, GroupSessionsService } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { FieldTimeOutlined } from "@ant-design/icons";
import HourUpdateModal from "./HourUpdateModal";
import {
  setSessionModals,
  setSessionDetail,
} from "../../../redux/sessionSlice";
import useSlots from "../../../hooks/useSlots";
import useQuery from "../../../hooks/useQuery";
const hourLabel = (labelName, slotList) => {
  const reservable = slotList?.find((item) => item.label === labelName);
  if (!reservable) {
    return (
      <span className="text-danger">
        <FieldTimeOutlined />

        {labelName}
      </span>
    );
  }
  return (
    <>
      <FieldTimeOutlined />
      {labelName}
    </>
  );
};
const GroupSessionForm = () => {
  const dispatch = useDispatch();
  const { queryData, queryLoading } = useQuery({
    service: ExpertService.getExpert,
    params: {},
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const sessionState = useSelector((state) => state.session.sessionDetail);
  const slotState = useSelector((state) => state.session.slot);

  const [form] = Form.useForm();
  const { expertId } = form.getFieldsValue();
  const [getExpertAvailabilities, loadingSlot] = useSlots();

  const expertList = () => {
    return queryData?.experts?.map((item) => ({
      value: item.expertId,
      label: (
        <div className="d-flex align-items-center">
          <img
            className="mr-2"
            src={item.profilePictureUrl}
            alt=""
            width={30}
          />
          {item.fullName}
        </div>
      ),
      searchExpert: item.fullName,
    }));
  };
  const onFinish = async (values) => {
    setLoadingSubmit(true);
    try {
      const body = {
        expertId,
        slotId: slotState.slotId,
        selectedSlots: [`S${sessionState.hourInfo.split(":").join("")}`],
        subject: values.subject,
        price: Number(values.price),
        currencyCode: values.currencyCode,
        seatCount: Number(values.seatCount),
      };
      console.log(body);
      const response = await GroupSessionsService.createGroupSession(body);

      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }
      message.success("İşlem Başarılı.");
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    getExpertAvailabilities();
  }, []);

  return (
    <>
      <Card loading={queryLoading} className="mb-3 col-12 col-md-12 col-lg-12">
        <Form onFinish={onFinish} form={form} layout="vertical">
          <Card className="mb-3">
            <div className="row">
              <Form.Item
                label="Konu"
                className="col-12 col-md-6 col-lg-3"
                name={"subject"}
                rules={[
                  {
                    required: true,
                    message: "Lütfen Konu alanını doldurun.",
                  },
                ]}
              >
                <Input placeholder="Konu Yazınız" size="large" name="title" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Lütfen Fiyat alanını doldurun." },
                ]}
                label="Fiyat"
                className="col-12 col-md-6 col-lg-3"
                name={"price"}
              >
                <Input
                  type="number"
                  size="large"
                  name="price"
                  placeholder="Fiyat Yazınız"
                  addonAfter={
                    <Form.Item
                      rules={[{ required: true, message: "Birim Seçiniz." }]}
                      className="mb-0"
                      name="currencyCode"
                    >
                      <Select
                        size="large"
                        placeholder="Kod"
                        style={{ width: 140 }}
                        options={[
                          { value: "TRY", label: "TRY" },
                          { value: "AZN", label: "AZN" },
                          { value: "GBP", label: "GBP" },
                        ]}
                      />
                    </Form.Item>
                  }
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Lütfen Koltuk Sayısı alanını doldurun.",
                  },
                ]}
                label="Koltuk Sayısı"
                className="col-12 col-md-6 col-lg-3"
                name={"seatCount"}
              >
                <Input
                  type="number"
                  size="large"
                  name="seatCount"
                  placeholder="Koltuk Sayısı Yazınız"
                />
              </Form.Item>
            </div>
          </Card>
          <Card>
            <div className="row">
              <Form.Item
                label="Seans Uzmanı"
                className="col-12 col-md-6 col-lg-3"
                initialValue={sessionState.expertId}
                name={"expertId"}
              >
                <Select
                  optionFilterProp="searchExpert"
                  disabled={queryLoading}
                  loading={queryLoading}
                  showSearch
                  allowClear
                  name={"expertId"}
                  placeholder="Seans Uzmanını Seçiniz"
                  options={expertList()}
                  size="large"
                  onChange={(value) => {
                    getExpertAvailabilities({ expertId: value });
                    dispatch(
                      setSessionDetail({ ...sessionState, expertId: value })
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Seans Zamanı"
                className="mb-3 col-12 col-md-6 col-lg-3"
              >
                <Button
                  className="w-100"
                  disabled={loadingSlot}
                  loading={loadingSlot}
                  onClick={() => {
                    dispatch(setSessionModals({ sessionHourUpdate: true }));
                  }}
                  size="large"
                  style={{ justifyContent: "flex-start" }}
                >
                  {hourLabel(sessionState.hourInfo, slotState.slotHours)}
                </Button>
              </Form.Item>
            </div>
          </Card>

          <Form.Item>
            <Button
              loading={loadingSubmit}
              disabled={loadingSubmit}
              type="primary"
              className="w-100"
              size="large"
              htmlType="submit"
            >
              Düzenle
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <HourUpdateModal />
    </>
  );
};
export default GroupSessionForm;
