import Logo from "../../images/logo.webp";
import CustomSpinner from "../components/Spinner";
function Layout(props) {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#01c897",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={Logo} />
        {props?.text ? (
          <h4 className="text-white">{props.text}</h4>
        ) : (
          <CustomSpinner color="white" />
        )}
      </div>
    </div>
  );
}
export default Layout;
