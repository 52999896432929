import React, { useState } from "react";
import { Affix, Form, Button, Select, Card, Input, TextArea } from "antd";
import { ContentService } from "../../../../api";
export default function NewPodcast() {
  return (
    <Card className="mb-3 " title="Podcast İçerik ">
      <div className="row">
        <Form.Item
          label="Ücretsiz Gösterim URL"
          className="col-12 col-md-4 col-lg-4"
          name={"previewContentUrl"}
          rules={[
            {
              required: true,
              message: "Lütfen URL alanını doldurun.",
            },
          ]}
        >
          <Input
            placeholder="URL Yazınız"
            size="large"
            name="previewContentUrl"
          />
        </Form.Item>
        <Form.Item
          label="Ücretli Gösterim URL"
          className="col-12 col-md-4 col-lg-4"
          name={"fullContentUrl"}
          rules={[
            {
              required: true,
              message: "Lütfen URL alanını doldurun.",
            },
          ]}
        >
          <Input placeholder="URL Yazınız" size="large" name="fullContentUrl" />
        </Form.Item>
        <Form.Item
          label="Dinleme Süresi (dk)"
          className="col-12 col-md-4 col-lg-4"
          name={"listenLengthInMinutes"}
          rules={[
            {
              required: true,
              message: "Lütfen Süre alanını doldurun.",
            },
          ]}
        >
          <Input
            placeholder="Süre Yazınız"
            size="large"
            name="listenLengthInMinutes"
            type="number"
          />
        </Form.Item>
      </div>
    </Card>
  );
}
