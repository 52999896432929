import React, { useEffect, useState } from "react";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { ContentService } from "../../../api";
import DetailHeader from "../../components/DetailHeader";

import {
  Card,
  Skeleton,
  message,
  Dropdown,
  Button,
  Typography,
  Tabs,
} from "antd";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import ContentForm from "../../components/Forms/Content";
const ContentDetail = () => {
  const { contentId, contentType } = useParams();
  const [loading, setLoading] = useState(true);
  const [detailData, setDetailData] = useState();

  const navigate = useNavigate();

  const getContentDetail = async () => {
    try {
      const response = await ContentService.getContentDetail(contentId);
      console.log(response.data.data);
      setDetailData(response.data.data);
    } catch (error) {
      console.log(error);
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  const ActionComponent = () => {
    const actionContent = async (action) => {
      try {
        switch (action) {
          case "deleted":
            var response = await ContentService.deleteContent(contentId);
            break;
          default:
            break;
        }
        if (response.data.status === "failed") {
          message.error(response.data.msgDetail);
          return;
        }
        message.success("İşlem Başarılı!");
        setTimeout(() => {
          navigate("/contents");
        }, 500);
      } catch (error) {
        message.error(JSON.stringify(error));
      }
    };
    return (
      <Dropdown
        placement="bottomRight"
        menu={{
          items: [
            {
              key: "delete",
              label: (
                <Button
                  size="large"
                  onClick={() => actionContent("deleted")}
                  type="text"
                  className="text-red"
                >
                  <CloseOutlined />
                  İçeriği Sil
                </Button>
              ),
            },
          ],
        }}
      >
        <Button type="text">
          <Typography>
            İşlemler
            <DownOutlined className="ml-2" />
          </Typography>
        </Button>
      </Dropdown>
    );
  };
  useEffect(() => {
    getContentDetail();
  }, []);
  return (
    <>
      <DetailHeader actionComponent={<ActionComponent />} />

      {loading ? (
        <Card>
          <Skeleton className="mb-4" active />
          <Skeleton className="mb-4" active />
        </Card>
      ) : (
        <ContentForm
          action="update"
          contentType={contentType}
          detailData={detailData}
        />
        // <Tabs
        //   defaultActiveKey="1"
        //   items={[
        //     {
        //       key: "1",
        //       label: "İçerik Detay",
        //       children: <div>detay</div>,
        //     },
        //     {
        //       key: "2",
        //       label: "İçerik Düzenle",
        //       children: (
        //         <ContentForm
        //           action="update"
        //           contentType={contentType}
        //           detailData={detailData}
        //         />
        //       ),
        //     },
        //   ]}
        // />
      )}
    </>
  );
};
export default ContentDetail;
