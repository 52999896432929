import axiosInstance from "./axiosConfig";
const api = {
  getSessions: (props) => {
    const params = {
      startDate: props?.startDate,
      endDate: props?.endDate,
      size: 100000,
    };
    return axiosInstance.get("/v1/sessions/all", { params });
  },
  getSession: (id) => {
    const url = "/v1/sessions/" + id;
    return axiosInstance.get(url);
  },
  rescheduleSession: (body, sessionId) => {
    let url = "/v1/sessions";
    url += `/${sessionId}`;
    url += `/reschedule`;
    return axiosInstance.put(url, body);
  },
  reassignSession: (body, sessionId) => {
    let url = "/v1/sessions";
    url += `/${sessionId}`;
    url += `/reassign`;
    return axiosInstance.put(url, body);
  },
  completeSession: (body, sessionId) => {
    let url = "/v1/sessions";
    url += `/${sessionId}`;
    url += `/complete-manually`;
    return axiosInstance.post(url, body);
  },
  rejectSession: (body, sessionId) => {
    let url = "/v1/sessions";
    url += `/${sessionId}`;
    url += `/cancel`;
    axiosInstance.defaults.headers["X-App-Type"] = "CLIENT";
    return axiosInstance.post(url, body);
  },
  waitingForStart: (sessionId) => {
    axiosInstance.defaults.headers["X-App-Type"] = "CLIENT";
    return axiosInstance.put(`/v1/sessions/${sessionId}/waiting-for-start`);
  },
  changeMeetingType: (body, sessionId) => {
    let url = "/v1/sessions";
    url += `/${sessionId}`;
    url += `/meeting-type`;
    return axiosInstance.put(url, body);
  },
};
export default api;
