import { useNavigate, useParams } from "react-router-dom";
import { Card, Input, Form, Button, Select, message } from "antd";
import constants from "../../../../constants";
import { NotificationService } from "../../../../api";
import { useState } from "react";
const NotificationForm = ({ detail, action }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      values = {
        notificationType: values.notificationType,
        dataTuple: {
          TR: values.dataTupleTr,
          EN: values.dataTupleEn,
        },
        titleTuple: {
          TR: values.titleTupleTr,
          EN: values.titleTupleEn,
        },
      };
      switch (action) {
        case "create":
          var response = await NotificationService.newNotificaiton(values);
          break;
        case "put":
          var response = await NotificationService.updateNotificaiton(
            detail.templateId,
            values
          );
          break;

        default:
          break;
      }

      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }
      navigate("/notifications");
      message.success("İşlem başarılı.");
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form scrollToFirstError form={form} onFinish={onFinish} layout="vertical">
      <Card className="mb-3" title="Bildirim Tipi">
        <div className="row">
          <Form.Item
            className="col-12 col-md-6 col-lg-3"
            name={"notificationType"}
            initialValue={detail?.notificationType}
          >
            <Select
              name={"notificationType"}
              allowClear
              placeholder="Bildirim Tipi Seçiniz"
              options={constants.notificationTypes}
              defaultValue={detail?.notificationType}
              size="large"
              disabled={action === "put"}
            />
          </Form.Item>
        </div>
      </Card>
      <Card title="Başlık" className="mb-3 ">
        <div className="row">
          <Form.Item
            label=""
            name={"titleTupleTr"}
            initialValue={detail?.titleTuple?.TR}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input
              name="titleTupleTr"
              size="large"
              addonBefore={"TR"}
              placeholder="Başlık TR Yazınız.."
              defaultValue={detail?.titleTuple?.TR}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="titleTupleEn"
            initialValue={detail?.titleTuple?.EN}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input
              name="titleTupleEn"
              size="large"
              addonBefore={"EN"}
              placeholder="Başlık EN Yazınız.."
              defaultValue={detail?.titleTuple?.EN}
            />
          </Form.Item>
        </div>
      </Card>
      <Card title="İçerik">
        <div className="row">
          <Form.Item
            label=""
            name={"dataTupleTr"}
            initialValue={detail?.dataTuple?.TR}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input
              name="dataTupleTr"
              size="large"
              addonBefore={"TR"}
              placeholder="İçerik TR Yazınız.."
              defaultValue={detail?.dataTuple?.TR}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="dataTupleEn"
            initialValue={detail?.dataTuple?.EN}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input
              name="dataTupleEn"
              size="large"
              addonBefore={"EN"}
              placeholder="İçerik EN Yazınız.."
              defaultValue={detail?.dataTuple?.EN}
            />
          </Form.Item>
        </div>
      </Card>

      <Form.Item label="">
        <Button
          loading={loading}
          size="large"
          className="w-100 mt-3"
          htmlType="submit"
          type="primary"
        >
          {action === "put" ? "Değiştir" : action === "create" ? "Ekle" : ""}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default NotificationForm;
