import React from "react";
import DetailHeader from "../../components/DetailHeader";
import CategoryForm from "../../components/Forms/Category";
const NewCategory = () => {
  return (
    <>
      <DetailHeader />
      <CategoryForm action={"create"} />
    </>
  );
};
export default NewCategory;
