import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DetailHeader } from "../../components";
import { message, Skeleton } from "antd";
import { NotificationService } from "../../../api";
import NotificationForm from "../../components/Forms/Notification/";

const NotificationDetail = () => {
  const { templateId } = useParams();
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);

  const getNotification = async () => {
    try {
      if (!templateId) return;
      const response = await NotificationService.getNotifications();

      setNotification(
        response.data.data.templates.find(
          (item) => item.templateId === templateId
        )
      );
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getNotification();
  }, []);
  return loading ? (
    <Skeleton active />
  ) : (
    <>
      <DetailHeader />
      <NotificationForm action="put" detail={notification} />
    </>
  );
};
export default NotificationDetail;
