import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "PODCAST",
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = contentSlice.actions;

export default contentSlice.reducer;
