import { useNavigate, useNavigation } from "react-router-dom";
import { Button, Affix, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CustomeDetailHeader = ({ actionComponent }) => {
  const navigate = useNavigate();
  return (
    <Affix offsetTop={window.innerWidth < 800 ? 80 : 120}>
      <div className="detail-header mb-4">
        <Button
          type="text"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Typography>
            <ArrowLeftOutlined className="mr-2" />
            Geri
          </Typography>
        </Button>
        {actionComponent}
      </div>
    </Affix>
  );
};
export default CustomeDetailHeader;
