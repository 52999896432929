import React from "react";
import DetailHeader from "../../components/DetailHeader";
import ContentForm from "../../components/Forms/Content";
import { useParams } from "react-router-dom";
const NewContent = () => {
  const { contentType } = useParams();
  return (
    <>
      <DetailHeader />
      <ContentForm action="create" contentType={contentType} />
    </>
  );
};
export default NewContent;
