import axiosInstance from "./axiosConfig";
const api = {
  getSubscriptions: (props) => {
    const params = {
      startDate: props?.startDate || "2020-02-01",
    };
    return axiosInstance.get("/v1/subscriptions/all", { params });
  },
  cancelSubscription: (id) => {
    return axiosInstance.put(`/v1/subscriptions/${id}/cancel`);
  },
};
export default api;
