import React, { useState } from "react";
import EditorJS from "../../EditorJS";
import { Form, Button, Card, Input } from "antd";
import { ContentService } from "../../../../api";
export default function NewArticle({ form }) {
  const [modals, setModals] = useState({
    freeBlog: false,
    blog: false,
  });

  return (
    <>
      <Card className="mb-3 " title="Blog İçerik ">
        <div className="row">
          <Form.Item
            label="Okuma Süresi (dk)"
            className="col-12 col-md-4 col-lg-4"
            name={"readLengthInMinutes"}
            rules={[
              {
                required: true,
                message: "Lütfen Süre alanını doldurun.",
              },
            ]}
          >
            <Input
              placeholder="Süre Yazınız"
              size="large"
              name="readLengthInMinutes"
              type="number"
            />
          </Form.Item>
        </div>
        <div className="row">
          <Form.Item
            name={"freeBlog"}
            label="Ücretsiz Blog"
            className="col-12 col-md-4 col-lg-6"
          >
            <Button
              className="w-100"
              onClick={() => {
                setModals({ freeBlog: true });
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
            >
              Ücretsiz Blog
            </Button>
          </Form.Item>
          <Form.Item
            name={"blog"}
            label="Ücretli Blog"
            className="col-12 col-md-4 col-lg-6"
          >
            <Button
              className="w-100"
              onClick={() => {
                setModals({ blog: true });
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
            >
              Ücretli Blog
            </Button>
          </Form.Item>
        </div>
      </Card>

      <EditorJS
        placeholder="Lütfen Ücretsiz Blog Yazınız"
        title="Ücretsiz Blog"
        holder="freeBlogForm"
        name="freeBlog"
        openModal={modals.freeBlog}
        setModal={(value) => setModals({ freeBlog: value })}
        form={form}
        defaultBlocks={form.getFieldsValue()?.freeBlog?.blocks || []}
      />

      <EditorJS
        placeholder="Lütfen Ücretli Blog Yazınız"
        title="Ücretli Blog"
        holder="blogForm"
        name="blog"
        openModal={modals.blog}
        setModal={(value) => setModals({ blog: value })}
        form={form}
        defaultBlocks={form.getFieldsValue()?.blog?.blocks || []}
      />
    </>
  );
}
