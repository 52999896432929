import React, { useState } from "react";

/// Components
import Markup from "./jsx";

/// Style
import "./css/style.css";

import { Provider } from "react-redux";
import { store } from "./jsx/redux/store";

import { withResizeDetector } from "react-resize-detector";
import { ConfigProvider, theme } from "antd";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import updateLocale from "dayjs/plugin/updateLocale";
import locale from "antd/es/locale/tr_TR";

dayjs.extend(updateLocale);
dayjs.updateLocale("tr");

const App = ({ width }) => {
  const body = document.querySelector("body");
  const { darkAlgorithm, defaultAlgorithm, defaultConfig } = theme;
  const [themeProject, setThemeProject] = useState(
    localStorage.getItem("theme")
  );

  width >= 1300
    ? body.setAttribute("data-sidebar-style", "full")
    : width <= 1299 && width >= 767
    ? body.setAttribute("data-sidebar-style", "mini")
    : body.setAttribute("data-sidebar-style", "overlay");

  return (
    <Provider store={store}>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: "#01c897",
            borderRadius: "1px",
          },
          components: {
            Card: {},
          },
          algorithm:
            themeProject === "default"
              ? defaultAlgorithm
              : themeProject === "dark"
              ? darkAlgorithm
              : defaultAlgorithm,
        }}
      >
        <Markup />
      </ConfigProvider>
    </Provider>
  );
};

export default withResizeDetector(App);
