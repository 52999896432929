import AgGridTable from "../../AgGridTable";
import dayjs from "dayjs";
import { useState } from "react";
import { Button, Card, Modal, Form, Input, DatePicker, Popconfirm } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { addEducation, deleteEducation } from "../../../redux/newExpertSlice";
const EducationItem = () => {
  const format = "YYYY-MM-DD";
  const dispatch = useDispatch();

  const educations = useSelector((state) => state.newExpert.educations);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [columnDefs, _] = useState([
    { field: "university", headerName: "Üniversite", minWidth: 250 },
    { field: "educationType", headerName: "Eğitim Türü", minWidth: 250 },
    { field: "field", headerName: "Alan Adı", minWidth: 250 },
    {
      field: "startDate",
      headerName: "Başlangıç Tarihi",
      cellRenderer: (params) => {
        return dayjs(params.data.startDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "endDate",
      headerName: "Bitiş Tarihi",
      cellRenderer: (params) => {
        return dayjs(params.data.endDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "",
      headerName: "Sil",
      cellRenderer: (params) => {
        return (
          <Popconfirm
            okText="Evet"
            placement="top"
            title="Uyarı"
            description="Silmek istediğinize emin misiniz?"
            onConfirm={() => {
              dispatch(deleteEducation(params.data.id));
            }}
          >
            <Button className="text-red" type="text" size="small">
              <CloseOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ]);
  const onFinish = (values) => {
    const dates = {
      startDate: dayjs(values.year[0]).format(format),
      endDate: dayjs(values.year[1]).format(format),
    };
    delete values.year;
    form.resetFields();
    dispatch(
      addEducation({
        ...values,
        ...dates,
        id: Math.random().toString(36).substr(2, 9),
      })
    );
    setIsModalOpen(false);
  };
  return (
    <Card
      className="mb-3"
      title="Eğitim Listesi"
      extra={
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          <PlusOutlined />
          Yeni Ekle
        </Button>
      }
    >
      <AgGridTable
        getRowId={(params) => {
          return params.data.id;
        }}
        pagination={false}
        noRowsOverlayComponent={() => "Veri bulunamadı."}
        rowData={educations}
        columnDefs={columnDefs}
        height={300}
        closeHeader
        defaultColDef={{
          filter: false,
        }}
      />
      <Modal
        title="Yeni Eğitim Ekle"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={600}
        centered
        cancelText="İptal"
        okText="Ekle"
        footer={false}
      >
        <Card>
          <Form
            layout="vertical"
            autoComplete="off"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Üniversite"
              name="university"
            >
              <Input
                name="university"
                size="large"
                placeholder="Üniversite Yazınız"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Eğitim Türü"
              name="educationType"
            >
              <Input
                name="educationType"
                size="large"
                placeholder="Eğitim Türü Yazınız"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Alan Adı"
              name="field"
            >
              <Input name="field" size="large" placeholder="Alan Adı Yazınız" />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Boş bırakılamaz." }]}
              label="Eğitim Tarihleri"
              name="year"
            >
              <DatePicker.RangePicker
                format={"DD-MM-YYYY"}
                name={["startDate", "endDate"]}
                className="w-100"
                size="large"
                placeholder={["Başlangıç", "Bitiş"]}
              />
            </Form.Item>
            <Form.Item key="submit">
              <Button
                size="large"
                className="w-100"
                htmlType="submit"
                type="primary"
              >
                Ekle
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </Card>
  );
};
export default EducationItem;
