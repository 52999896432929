import React from "react";
import AgGridTable from "../../components/AgGridTable";
import { SubsService } from "../../../api";
import useQuery from "../../hooks/useQuery";
import { subsListColumn } from "../../components/Columns";
import { useParams } from "react-router-dom";
const UserSubsDetail = () => {
  const { queryData, queryLoading } = useQuery({
    service: SubsService.getSubscriptions,
  });
  const { userId } = useParams();
  const filterUser = queryData?.subscriptions.filter(
    (user) => user.userId == userId
  );
  return (
    <>
      <AgGridTable
        loading={queryLoading}
        rowData={filterUser}
        columnDefs={subsListColumn}
        sideBar={"filters"}
      />
    </>
  );
};

export default UserSubsDetail;
