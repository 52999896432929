import axiosInstance from "./axiosConfig";
const api = {
  getComment: (id) => {
    let url = "/v1/feedback";
    const params = {
      page: 0,
      size: 100000000,
    };

    if (id) url += `/${id}`;

    return axiosInstance.get(url, { params });
  },
  approveComment: (id) => {
    return axiosInstance.put(`/v1/feedback/${id}/approve`);
  },
};
export default api;
