import { useEffect, useState } from "react";
import { Card, Form, Select, message } from "antd";
import { ExpertService } from "../../../../api";
import { useSelector, useDispatch } from "react-redux";
import { setExpertDetail } from "../../../redux/newExpertSlice";
const ExpertInfo = () => {
  const dispatch = useDispatch();
  const expertDetailState = useSelector(
    (state) => state.newExpert.expertDetail
  );
  const [data, setData] = useState({
    types: [],
    titles: [],
  });
  const [loading, setLoading] = useState(true);

  const getExpertises = async () => {
    try {
      const services = [
        ExpertService.getExpertTypes(),
        ExpertService.getExpertTitles(),
      ];
      const [typesResponse, titlesResponse] = await Promise.all(services);

      const types = typesResponse.data.data.expertises.map((item) => ({
        value: item.type,
        label: item.text,
      }));
      const titles = titlesResponse.data.data.titles.map((item) => ({
        value: item.type,
        label: item.subText,
      }));
      setData({ titles, types });
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  const onChangeExpertises = (expertises) => {
    let newDetail = JSON.parse(JSON.stringify(expertDetailState));
    newDetail.expertises = expertises.map((item) => ({ value: item }));
    dispatch(setExpertDetail(newDetail));
  };
  useEffect(() => {
    getExpertises();
  }, []);
  return (
    <Card className="mb-3" title="Uzmanlıklar" loading={loading}>
      <div className="row">
        <Form.Item
          label="Uzmanlık Tipi"
          name={"titleType"}
          initialValue={expertDetailState?.title?.type}
          className="col-12 col-md-6 col-lg-3"
        >
          <Select
            showSearch
            optionFilterProp="label"
            name={"titleType"}
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Uzmanlık Tipi Seçiniz"
            options={data.titles}
            size="large"
          />
        </Form.Item>
        <Form.Item
          className="col-12 col-md-6 col-lg-3"
          label="Uzmanlık Alanları"
          name={"expertises"}
          initialValue={expertDetailState?.expertises?.map((item) => ({
            value: item.type,
            label: item.text,
          }))}
        >
          <Select
            name={"expertises"}
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Uzmanlık Alanı Seçiniz"
            options={data.types}
            size="large"
            onChange={onChangeExpertises}
          />
        </Form.Item>
      </div>
    </Card>
  );
};
export default ExpertInfo;
