import { configureStore } from "@reduxjs/toolkit";

import newExpertSlice from "./newExpertSlice";
import notificationSlice from "./notificationSlice";
import sessionSlice from "./sessionSlice";
import contentSlice from "./content";
export const store = configureStore({
  reducer: {
    newExpert: newExpertSlice,
    notificaiton: notificationSlice,
    session: sessionSlice,
    content: contentSlice,
  },
});
