import React, { useState, useEffect } from "react";
import AgGridTable from "../../components/AgGridTable";
import { SessionService } from "../../../api";
import { Form, DatePicker, message } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { sessionsListColumn } from "../../components/Columns";
const Sessions = () => {
  const defaultValue = [dayjs(), dayjs().endOf("month")];

  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(true);
  const [forms] = Form.useForm();
  const navigate = useNavigate();

  const getSessions = async (props) => {
    try {
      const response = await SessionService.getSessions({
        startDate: props?.startDate || defaultValue[0].format("YYYY-MM-DD"),
        endDate: props?.endDate || defaultValue[1].format("YYYY-MM-DD"),
      });
      const sessions = response?.data?.data?.sessions;

      if (sessions) {
        setRowData(sessions);
      }
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    values = values?.map((value) => dayjs(value).format("YYYY-MM-DD"));
    if (!values?.length) return;
    getSessions({
      startDate: values[0],
      endDate: values[1],
    });
  };
  const onRowClick = ({ data }) => {
    const url = "/session-detail/" + data.sessionId;
    navigate(url);
  };
  useEffect(() => {
    getSessions();
  }, []);
  return (
    <AgGridTable
      getRowId={(params) => {
        return params.data.sessionId;
      }}
      loading={loading}
      onRowClicked={onRowClick}
      rowData={rowData}
      columnDefs={sessionsListColumn}
      sideBar={"filters"}
      headerChildren={
        <Form
          scrollToFirstError
          autoComplete="off"
          forms={forms}
          // onFinish={onFinish}
        >
          <Form.Item className="mb-0" label="Tarih" name="dates">
            <DatePicker.RangePicker
              defaultValue={defaultValue}
              onChange={onFinish}
              name={["startDate", "endDate"]}
              placeholder={["Başlangıç", "Bitiş"]}
              format={"DD-MM-YYYY HH:mm"}
              showTime
            />
          </Form.Item>
        </Form>
      }
    />
  );
};

export default Sessions;
