import React, { useState } from "react";

/// images
import logo from "../../../images/logo.png";
import { useNavigate } from "react-router-dom";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const navigate = useNavigate();
  return (
    <div className="nav-header" data-theme={theme}>
      <div className="cd-brand-logo-wp ">
        <img
          onClick={() => navigate("/dashboard")}
          className={`cd-brand-logo ${!toggle ? "" : "cd-brand-logo-passive"}`}
          src={logo}
          alt=""
        />
      </div>
      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
