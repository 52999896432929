const edjsHTML = require("editorjs-html");
function rawParser(block) {
  return `${block.data.html}`;
}
function imageParser(block) {
  return `<img  style="width:100%" src="${block.data.url}"/>`;
}
function listParser(block) {
  let ols = "";
  block.data.items.forEach((item, index) => {
    ols +=
      `
    <div style="display:flex;flex-direction:row;width:100%;" class="ols-item">
      <p style="width:90%;letter-spacing:-0.900px">
      ${item}
      </p>
    </div>
    ` + "\n";
  });
  return `
    <div >
      ${ols}
    </div>
  `;
}
const htmlJS = edjsHTML({
  raw: rawParser,
  image: imageParser,
  list: listParser,
});

const htmlParser = (block) => {
  if (typeof block !== "object") return "";
  return htmlJS.parseBlock(block);
};
const generateContainer = (html) => {
  return `
<style>
  .main {
    padding:10px;
  }
  .ols-item::before {
    content:"⬤";
    margin-top:5px;
    margin-right:2px;
    font-size:5px;
  }
</style>
<html>
  <div class='main'>
    ${html}
  <div>
</html>`;
};
module.exports = (blocks) => {
  let html = "";
  if (!blocks?.length) return generateContainer(html);
  //set blocks
  blocks.forEach((block) => {
    html += htmlParser(block) + "\n";
  });
  return generateContainer(html);
};
