import React, { useState, useCallback, useEffect } from "react";
import AgGridTable from "../../components/AgGridTable";
import { PromotionCodeService } from "../../../api";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import constants from "../../../constants";
import { promotionListColumn } from "../../components/Columns";
import { PlusOutlined } from "@ant-design/icons";
const PromotionCode = () => {
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [columnDefs, _] = useState(promotionListColumn);

  const getPromotionCodes = async () => {
    try {
      const response = await PromotionCodeService.getPromotions();
      const promotions = response?.data?.data?.promotions;
      if (promotions) {
        setRowData(promotions);
      }
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPromotionCodes();
  }, []);

  return (
    <AgGridTable
      loading={loading}
      // onRowClicked={(params) =>
      //   navigate(`/comment-detail/${params.data.feedbackId}`)
      // }
      rowData={rowData}
      columnDefs={columnDefs}
      sideBar={"filters"}
      headerChildren={
        <Button type="primary" onClick={() => navigate("/new-promotion-code")}>
          <PlusOutlined />
          Yeni Promosyon Kodu Ekle
        </Button>
      }
    />
  );
};

export default PromotionCode;
