import React from "react";

export default function ShadowBack(props) {
  return (
    <div
      {...props}
      className=""
      style={{
        background: "rgba(0, 0, 0, 0.45)",
        width: "100vw",
        height: "200vh",
        position: "fixed",
        left: 0,
        zIndex: 2000,
        top: 0,
      }}
    ></div>
  );
}
