import React, { useState, useEffect } from "react";
import AgGridTable from "../../components/AgGridTable";
import { NotificationService } from "../../../api";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import constants from "../../../constants";
const Notifications = () => {
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [columnDefs, setColumnDefs] = useState([
    { field: "templateId", headerName: "ID" },
    {
      field: "notificationType",
      headerName: "Bildirim Tipi",
      cellRenderer: (params) => {
        return constants.notificationTypes.find(
          (item) => item.value === params.data.notificationType
        )?.label;
      },
    },
    { field: "dataTuple.TR", headerName: "Bildirim" },
    { field: "titleTuple.TR", headerName: "Bildirim Başlığı" },
  ]);

  const getNotifications = async () => {
    try {
      const response = await NotificationService.getNotifications();
      const notifications = response?.data?.data?.templates;
      if (notifications) {
        setRowData(notifications);
      }
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onRowClicked = (params) => {
    const url = `/notification-detail/` + params.data.templateId;
    navigate(url);
  };
  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <AgGridTable
      loading={loading}
      onRowClicked={onRowClicked}
      rowData={rowData}
      columnDefs={columnDefs}
      sideBar={"filters"}
      headerChildren={
        <Button type="primary" onClick={() => navigate("/new-notification")}>
          <PlusOutlined />
          Yeni Ekle
        </Button>
      }
    />
  );
};

export default Notifications;
