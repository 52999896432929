import React from "react";
import { Button } from "antd";
import dayjs from "dayjs";
import constants from "../../constants";

const partnerContentColumn = [
  {
    field: "contentId",
    headerName: "ID",
    minWidth: 220,
  },
  {
    field: "contentOwner.contentOwnerName",
    headerName: "İçerik Sahibi",
    minWidth: 220,
  },
  {
    field: "title",
    headerName: "Başlık",
    minWidth: 220,
  },
  {
    field: "summary",
    headerName: "Açıklama",
    minWidth: 220,
  },
  {
    field: "thumbnailUrl",
    headerName: "Arka Plan URL",
    minWidth: 220,
  },
  {
    field: "category.categoryName",
    headerName: "Kategori",
    minWidth: 220,
  },
  {
    field: "createdAt",
    headerName: "Oluşturulma Tarihi",
    minWidth: 220,
    cellRenderer: (params) => {
      return dayjs(params.data.createdAt).format("DD-MM-YY HH:mm");
    },
  },
];
export const groupSessionsColumn = [
  {
    field: "status",
    headerName: "Durum",
    cellRenderer: (params) => constants.groupSessionsStatus[params.data.status],
  },
  { field: "groupSessionId", headerName: "Seans ID" },
  { field: "expertId", headerName: "Uzman ID" },
  { field: "expertFullName", headerName: "Uzman Full Name" },
  { field: "expertFullName", headerName: "Uzman Full Name" },
  { field: "titleType.text", headerName: "Uzmanlık Alanı" },
  { field: "subject", headerName: "Seans Konusu" },
  {
    field: "sessionTime",
    headerName: "Seans Tarihi",

    cellRenderer: (params) =>
      dayjs(params.data.sessionTime).format("DD-MM-YY HH:mm"),
  },
  { field: "remainingTickets", headerName: "Boşta Bilet Sayısı" },
  { field: "monetaryInfo.text", headerName: "Ücret" },
];
export const promotionListColumn = [
  {
    field: "promotionStatus",
    headerName: "Durum",
    cellRenderer: (params) => {
      switch (params.data.promotionStatus) {
        case "ACTIVE":
          return (
            <Button type="primary" size="small">
              Aktif
            </Button>
          );

        default:
          return (
            <Button type="primary" className="bg-danger" size="small">
              Pasif
            </Button>
          );
      }
    },
  },
  { field: "title", headerName: "Başlık" },
  { field: "code", headerName: "Kod" },

  {
    field: "promotionType",
    headerName: "Promosyon Türü",
    cellRenderer: (params) =>
      constants.promotionTypes[params.data.promotionType] || "",
  },
  {
    field: "",
    headerName: "Tür İndirim Tutarı",
    cellRenderer: (params) => {
      switch (params.data.promotionType) {
        case "DISCOUNT_RATE":
          return params.data.discountRate;
        case "AMOUNT_BASED":
          return params.data.discountAmount;
      }
    },
  },
  { field: "availableCurrencyCode", headerName: "Birim Kodu" },
  {
    field: "expireDate",
    headerName: "Son Kullanım Tarihi",
    cellRenderer: (params) =>
      dayjs(params.data.expireDate).format("DD-MM-YY HH:mm"),
  },
  { field: "promotionId", headerName: "ID" },
  { field: "description", headerName: "Açıklama" },

  { field: "description", headerName: "Açıklama" },
];
export const subsListColumn = [
  { field: "subscriptionId", headerName: "Abonelik ID" },
  {
    field: "purchaseDate",
    headerName: "Abonelik Tarihi",
    cellRenderer: (params) =>
      dayjs(params.data.purchaseDate).format("DD-MM-YY HH:mm"),
  },
  {
    field: "expirationDate",
    headerName: "Son Kullanım Tarihi",

    cellRenderer: (params) =>
      dayjs(params.data.expirationDate).format("DD-MM-YY HH:mm"),
  },
  {
    field: "status",
    headerName: "Abonelik Durumu",
    cellRenderer: (params) => constants.subsStatus[params.data.status],
  },
  {
    field: "paymentStatus",
    headerName: "Ödeme Durumu",
    cellRenderer: (params) =>
      constants.subsPaymentStatus[params.data.paymentStatus],
  },
  {
    field: "subscriptionType",
    headerName: "Abonelik Türü",
    cellRenderer: (params) => constants.subsTypes[params.data.subscriptionType],
  },
];
export const articleListColumn = [...partnerContentColumn];
export const podcastListColumn = [...partnerContentColumn];
export const videoListColumn = [...partnerContentColumn];
export const categoriesListColumn = [
  {
    field: "sortOrder",
    headerName: "Sıralama",
    maxWidth: 200,
    // rowDrag: true,
    // suppressHeaderMenuButton: true,
  },
  {
    field: "categoryId",
    headerName: "ID",

    maxWidth: 200,
  },
  {
    field: "valueTuple.TR",
    headerName: "Başlık Türkçe",
    maxWidth: 350,
  },
  {
    field: "valueTuple.EN",
    headerName: "Başlık İngilizce",
    maxWidth: 350,
  },
  {
    field: "",
    headerName: "Renkler",
    maxWidth: 350,
    dataName: "categoryColors",
    cellRenderer: (params) => {
      return (
        <div
          style={{
            display: "flex",
            height: 30,
          }}
        >
          <div
            style={{
              width: 30,
              background: params.data.primaryColorCode,
              borderRadius: 5,
              marginRight: 5,
            }}
          ></div>
          <div
            style={{
              width: 30,
              background: params.data.secondaryColorCode,

              borderRadius: 5,
              marginRight: 5,
            }}
          ></div>
          <div
            style={{
              width: 30,
              background: params.data.tertiaryColorCode,

              borderRadius: 5,
            }}
          ></div>
        </div>
      );
    },
  },
];
export const sessionsListColumn = [
  {
    field: "sessionStatus.type",
    headerName: "Durum",
    cellRenderer: (params) => {
      const currentStatus = constants.sessionStatus.find(
        (item) => item.value === params.data.sessionStatus.type
      );
      return (
        <Button
          type="primary"
          size="small"
          className={currentStatus?.className || ""}
        >
          {currentStatus?.label}
        </Button>
      );
    },
    minWidth: 220,
  },
  {
    field: "meetingType",
    headerName: "Görüşme Seçeneği",
    cellRenderer: (params) => {
      const currentStatus = constants.meetingTypes[params.data.meetingType];
      return (
        <Button size="small" className={currentStatus?.className || ""}>
          {currentStatus}
        </Button>
      );
    },
    minWidth: 220,
  },
  {
    field: "sessionType",
    headerName: "Tür",
    cellRenderer: (params) => {
      return constants.sessionTypes[params.data.sessionType] || "Boş";
    },
    minWidth: 200,
  },
  {
    field: "paymentType",
    headerName: "Ödeme Tür",
    cellRenderer: (params) => {
      return constants.sessionPaymentTypes[params.data.paymentType] || "BOŞ";
    },
    minWidth: 200,
  },
  { field: "sessionId", headerName: "ID", minWidth: 200 },
  {
    field: "sessionTime",
    headerName: "Seans Tarihi",
    cellRenderer: (params) => {
      return dayjs(params.data.sessionTime).format("DD-MM-YY HH:mm");
    },
    minWidth: 200,
  },
  {
    field: "createdAt",
    headerName: "Seans Satın Alma Tarihi",
    cellRenderer: (params) => {
      return dayjs(params.data.sessionTime).format("DD-MM-YY HH:mm");
    },
    minWidth: 200,
  },
  { field: "expertId", headerName: "Uzman ID", minWidth: 200 },
  { field: "expertFullName", headerName: "Uzman İsmi", minWidth: 200 },
  { field: "dayId", headerName: "Takvim ID", minWidth: 200 },
  { field: "client.clientId", headerName: "Kullanıcı ID", minWidth: 200 },
  { field: "client.fullName", headerName: "Kullanıcı İsmi", minWidth: 200 },
  { field: "client.nickname", headerName: "Kullanıcı Adı", minWidth: 200 },
  { field: "client.phone", headerName: "Kullanıcı Telefon", minWidth: 200 },
];
export const usersListColumn = [
  {
    field: "status",
    headerName: "Durum",
    cellRenderer: (params) => {
      switch (params.data.status) {
        case "ACTIVE":
          return (
            <Button type="primary" size="small">
              Aktif
            </Button>
          );

        default:
          return (
            <Button type="primary" className="bg-danger" size="small">
              Pasif
            </Button>
          );
      }
    },
  },
  { field: "userId", headerName: "ID" },
  { field: "fullName", headerName: "Kullanıcı Tam İsim" },
  { field: "nickname", headerName: "Kullanıcı Adı" },
  { field: "phone", headerName: "Telefon" },
  { field: "email", headerName: "Email" },
  {
    field: "registrationDate",
    headerName: "Kayıt Tarihi",

    cellRenderer: (params) => {
      return dayjs(params.data.registrationDate).format("DD/MM/YYYY HH:mm");
    },
    minWidth: 200,
  },
];
export const expertsListColumn = [
  {
    field: "userStatus.type",
    headerName: "Durum",
    cellRenderer: (params) => {
      switch (params.data.userStatus.type) {
        case "ACTIVE":
          return (
            <Button type="primary" size="small">
              Aktif
            </Button>
          );

        default:
          return (
            <Button type="primary" className="bg-danger" size="small">
              Pasif
            </Button>
          );
      }
    },
    filter: false,
  },
  {
    field: "profilePictureUrl",
    headerName: "Fotoğraf",
    cellRenderer: (params) => (
      <img
        style={{
          width: 40,
          height: 40,
          objectFit: "cover",
          borderRadius: "100%",
        }}
        src={params.data.profilePictureUrl}
      />
    ),
    filter: false,
  },
  {
    field: "available",
    headerName: "Müsaitlik",
    cellRenderer: (params) => {
      const stat = params.data.available;
      if (stat)
        return (
          <Button type="primary" size="small">
            Uygun
          </Button>
        );
      return "Değil";
    },
  },
  {
    field: "defaultTimezone",
    headerName: "Saat Dilimi",
    cellRenderer: (params) => {
      return params.data.defaultTimezone;
    },
    minWidth: 150,
  },
  {
    field: "firstName",
    headerName: "İsim",
  },
  {
    field: "lastName",
    headerName: "Soy İsim",
  },
  {
    field: "rating",
    headerName: "Puan",
  },

  {
    field: "phone",
    headerName: "Telefon",
  },
  {
    field: "expertId",
    headerName: "ID",
  },
  {
    field: "registrationStatus.type",
    headerName: "Kayıt Durumu",
    cellRenderer: (params) => {
      switch (params.data.registrationStatus.type) {
        case "COMPLETED":
          return (
            <Button type="primary" size="small">
              Tamamlandı
            </Button>
          );

        default:
          return "Tamamlanmadı";
      }
    },
    minWidth: 120,
  },
  {
    field: "title.text",
    headerName: "Uzmanlık Alanı",
    minWidth: 130,
  },
  {
    field: "title.type",
    headerName: "Uzmanlık Kodu",
    minWidth: 130,
  },
  {
    field: "education",
    headerName: "Mezuniyet",
  },
  {
    field: "overallServiceMinutes",
    headerName: "Toplam Hizmet(dakika)",
    minWidth: 175,
  },
  {
    field: "priceInfo.price",
    headerName: "Ücret",
  },
  {
    field: "priceInfo.currencyCode",
    headerName: "Birim Kodu",
    minWidth: 130,
  },
];
