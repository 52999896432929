import axiosInstance from "./axiosConfig";
const api = {
  getUsers: (id) => {
    let url = "/v1/users/all";
    const params = {
      size: 100000000,
    };
    return axiosInstance.get(url, { params });
  },
};
export default api;
