import { Card, Form, Select, Button, message } from "antd";
import constants from "../../../../constants";
import { SessionService } from "../../../../api";
export default function MeetingTypeUpdate({ meetingType, sessionId }) {
  const resultMeetingTypeOptions = () => {
    return Object.keys(constants.meetingTypes).map((item) => ({
      label: constants.meetingTypes[item],
      value: item,
    }));
  };
  const onFinish = async (values) => {
    try {
      const response = await SessionService.changeMeetingType(
        values,
        sessionId
      );
      message.success("Başarılı");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card className="mb-3 col-12 col-md-12 col-lg-12">
      <Form layout="vertical" onFinish={onFinish}>
        <div className="row">
          <Form.Item
            label="Görüşme Seçeneği"
            className="col-12 col-md-6 col-lg-3"
            name={"meetingType"}
            initialValue={meetingType}
          >
            <Select
              showSearch
              allowClear
              name={"meetingType"}
              options={resultMeetingTypeOptions()}
              optionFilterProp="label"
              placeholder="Görüşme Seçeneği Seçiniz"
              size="large"
              onChange={(value) => {}}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="primary"
            className="w-100"
            size="large"
            htmlType="submit"
          >
            Düzenle
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
