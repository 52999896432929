import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { ExpertService } from "../../api";
import { setSlot } from "../redux/sessionSlice";
import { message } from "antd";
import { useState } from "react";
export default function useSlots() {
  const sessionState = useSelector((state) => state.session.sessionDetail);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getExpertAvailabilities = async (props) => {
    setLoading(true);
    try {
      const response = await ExpertService.getExpertAvailability({
        id: props?.expertId || sessionState.expertId,
        timeAvailability: dayjs(
          props?.sessionTime || sessionState.sessionTime
        ).format("DD-MM-YYYY"),
      });

      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        dispatch(
          setSlot({
            slotId: "",
            slotHours: [],
          })
        );
        return;
      }
      dispatch(
        setSlot({
          slotId: response?.data?.data?.slotId,
          slotHours: response?.data?.data?.slotList.map((item) => ({
            label: item.text,
            value: item.slot,
            reservable: item.reservable,
          })),
        })
      );
    } catch (error) {
      message.error(error?.msgDetail || "Bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  return [getExpertAvailabilities, loading];
}
