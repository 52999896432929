import React from "react";
import { Card, Button } from "antd";
import constants from "../../constants";
const ExpertSlotItem = ({ item, isActive }) => {
  return (
    <Button
      className={`col-lg-1 col-md-6 text-white ${
        isActive ? "expert-slot-item-passive " : "expert-slot-item-active"
      }`}
    >
      {item}
    </Button>
  );
};
export default function ExpertSlot({ expertHours, slots }) {
  const numberTimeHours = (item) => {
    item = item.split(":").join("");
    item = Number(item);
    return item;
  };
  const stringHours = (item) => {
    item = item.split("S")[1];
    item = String(item).substring(0, 2) + `:` + String(item).substring(2, 4);
    return item;
  };
  const numberHours = (item) => {
    item = item.split("S")[1];
    item = Number(item);
    return item;
  };
  const toStringHours = (hour) => {
    hour = hour.toString();
    if (hour.length < 4) hour = `0${hour}`;

    hour = String(hour).substring(0, 2) + `:` + String(hour).substring(2, 4);
    return hour;
  };

  const startSlot = numberHours(slots.startSlot);
  const endSlot = numberHours(slots.endSlot);

  const filterHour = (hour) => {
    if (hour < startSlot) {
      return null;
    }
    if (hour > endSlot) {
      return null;
    }
    return hour;
  };

  const sliceHours = () => {
    const numberCHours = constants.hours.map(numberTimeHours);
    const filterCHours = numberCHours.filter(filterHour);
    const mapCHours = filterCHours.map(toStringHours);
    return mapCHours;
  };

  const newExpertHours = expertHours.map(stringHours);

  const hours = sliceHours();
  return (
    <Card>
      <div className="row">
        {hours.map((item) => (
          <ExpertSlotItem
            item={item}
            isActive={newExpertHours.includes(item)}
          />
        ))}
      </div>
    </Card>
  );
}
