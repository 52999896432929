import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Expert Service
import { ExpertService } from "../../../api";

//Components
import DetailHeader from "../../components/DetailHeader";
import ExpertForm from "../../components/Forms/ProfileExpert";
import ExpertSlot from "../../components/ExpertSlot";

import { useDispatch, useSelector } from "react-redux";
import {
  setExpertDetail,
  setCertificates,
  setEducations,
} from "../../redux/newExpertSlice";
import {
  Card,
  Skeleton,
  message,
  Tabs,
  Collapse,
  Badge,
  Dropdown,
  Button,
  Typography,
} from "antd";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import constants from "../../../constants";
const ExpertDetailTab = () => {
  const expertDetailState = useSelector(
    (state) => state.newExpert.expertDetail
  );

  const DayChildrenItem = ({ day }) => {
    return (
      <div>
        <p>{day.routineDescription}</p>
        <ExpertSlot
          expertHours={day.unavailableSlots}
          slots={{ endSlot: day.endSlot, startSlot: day.startSlot }}
        />
      </div>
    );
  };
  const generateItems = () => {
    const days = Object.keys(constants.days);
    const dayRoutines = (key) =>
      expertDetailState?.routineDetail?.dayRoutines[key];
    return days.map((key, index) => ({
      key: String(index),
      label: (
        <div>
          <Badge
            className="mr-3"
            status={dayRoutines(key).available ? "success" : "error"}
          />
          {constants.days[key]}
        </div>
      ),
      children: <DayChildrenItem day={dayRoutines(key)} />,
    }));
  };
  return (
    <Card title="Haftalık Rutin">
      <Collapse items={generateItems()} />
    </Card>
  );
};
const ExpertDetail = () => {
  const { expertId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const getExpertDetail = async () => {
    try {
      if (!expertId) return;
      const response = await ExpertService.getExpert({ id: expertId });
      const expert = response.data.data;
      dispatch(setExpertDetail(expert));
      dispatch(
        setCertificates(
          expert.certificateList.map((item) => ({
            ...item,
            id: Math.random().toString(36).substr(2, 9),
          }))
        )
      );
      dispatch(
        setEducations(
          expert.educationInfo.map((item) => ({
            ...item,
            id: Math.random().toString(36).substr(2, 9),
          }))
        )
      );
    } catch (error) {
      console.log(error);
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  const ActionComponent = () => {
    const actionExpert = async (action) => {
      try {
        switch (action) {
          case "blocked":
            var response = await ExpertService.blockedExpert(expertId);
            break;
          case "active":
            var response = await ExpertService.activetedExpert(expertId);
            break;

          default:
            break;
        }
        if (response.data.status === "failed") {
          message.error(response.data.msgDetail);
          return;
        }
        message.success("İşlem Başarılı!");
      } catch (error) {
        message.error(JSON.stringify(error));
      }
    };
    return (
      <Dropdown
        placement="bottomRight"
        menu={{
          items: [
            {
              key: "active",
              label: (
                <Button
                  size="large"
                  onClick={() => actionExpert("active")}
                  type="text"
                  className="text-green"
                >
                  <CloseOutlined />
                  Uzmanı Aktife Al
                </Button>
              ),
            },
            {
              key: "delete",
              label: (
                <Button
                  size="large"
                  onClick={() => actionExpert("blocked")}
                  type="text"
                  className="text-red"
                >
                  <CloseOutlined />
                  Uzmanı Pasife Al
                </Button>
              ),
            },
          ],
        }}
      >
        <Button type="text">
          <Typography>
            İşlemler
            <DownOutlined className="ml-2" />
          </Typography>
        </Button>
      </Dropdown>
    );
  };
  useEffect(() => {
    getExpertDetail();
  }, []);
  return (
    <>
      <DetailHeader actionComponent={<ActionComponent />} />

      {loading ? (
        <Card>
          <Skeleton className="mb-4" active />
          <Skeleton className="mb-4" active />
        </Card>
      ) : (
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Uzman Detay",
              children: <ExpertDetailTab />,
            },
            {
              key: "2",
              label: "Uzman Düzenle",
              children: <ExpertForm action={"put"} />,
            },
          ]}
        />
      )}
    </>
  );
};
export default ExpertDetail;
