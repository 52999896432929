import { useEffect, useState } from "react";
import { Card, message, Descriptions } from "antd";
import { ExpertService } from "../../api";
import getUser from "../../utils/getUser";
import DetailHeader from "../components/DetailHeader";
const ProfileExpert = () => {
  const [userDetail, setUserDetail] = useState();
  const [loading, setLoading] = useState(true);

  const getUserDetail = async () => {
    try {
      const user = getUser();
      const response = await ExpertService.getExpert({ id: user?.userId });
      setUserDetail(response.data.data);
    } catch (error) {
      console.log(error);
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserDetail();
  }, []);
  return (
    <>
      <DetailHeader />
      <Card loading={loading}>
        <Descriptions
          className="mt-3"
          bordered
          column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
          items={[
            {
              key: "1",
              label: "İsim Soyisim",
              children: userDetail?.fullName,
            },
            {
              key: "1123",
              label: "Telefon",
              children: userDetail?.phone,
            },
            {
              key: "11as3",
              label: "Email",
              children: userDetail?.email,
            },
          ]}
          size="small"
        />
      </Card>
    </>
  );
};
export default ProfileExpert;
