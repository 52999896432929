import React, { useEffect, useState } from "react";
import { Affix, Form, Button, message, Card, Input, TextArea } from "antd";
import { ContentService } from "../../../../api";
import ContentDetail from "./ContentDetail";
import ContentSettings from "./ContentSettings";
import NewPodcast from "./NewPodcast";
import NewVideo from "./NewVideo";
import NewArticle from "./NewArticle";
import edjsHTMLCon from "../../../../utils/edjsHTMLCon";
export default function ContentForm({ action, contentType, detailData }) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [form] = Form.useForm();
  const contents = {
    PODCAST: {
      component: <NewPodcast form={form} />,
      body: {
        previewContentUrl: "",
        fullContentUrl: "",
        listenLengthInMinutes: "",
      },
    },
    ARTICLE: {
      component: <NewArticle form={form} />,
    },
    VIDEO: {
      component: <NewVideo />,
      body: {
        previewContentUrl: "",
        fullContentUrl: "",
        watchLengthInMinutes: "",
      },
    },
  };
  const createContentBody = (datas) => {
    let currentValue = {};
    switch (contentType) {
      case "ARTICLE":
        const freeBlogHTML = edjsHTMLCon(datas.freeBlog?.blocks);
        const blogHTML = edjsHTMLCon(datas.blog?.blocks);
        currentValue = {
          previewText: freeBlogHTML,
          fullText: blogHTML,
          fullBlocks: datas.blog?.blocks || [],
          previewBlocks: datas.freeBlog?.blocks || [],
          readLengthInMinutes: Number(datas.readLengthInMinutes),
        };
        break;
      case "PODCAST":
        currentValue = {
          previewContentUrl: datas.previewContentUrl || "",
          fullContentUrl: datas.fullContentUrl,
          listenLengthInMinutes: Number(datas.listenLengthInMinutes),
        };
        break;
      case "VIDEO":
        currentValue = {
          previewContentUrl: datas.previewContentUrl || "",
          fullContentUrl: datas.fullContentUrl,
          watchLengthInMinutes: Number(datas.watchLengthInMinutes),
        };
        break;
    }
    return currentValue;
  };
  const onFinish = async (values) => {
    try {
      const body = {
        contentOwnerUserType:
          values.contentOwnerId === "HEALMEUP" ? "HEALMEUP" : "EXPERT", // HEALMEUP, EXPERT
        contentOwnerId:
          values.contentOwnerId === "HEALMEUP" ? null : values.contentOwnerId, // HEALMEUP ise null, EXPERT ise expertId gelecek
        contentStatus: values?.contentStatus ? "PUBLISHED" : "DRAFT",
        title: values.title,
        summary: values.summary,
        thumbnailUrl: values.thumbnailUrl,
        content: {
          contentType,
          ...createContentBody(values),
        },
        contentType,
        categoryId: values.categoryId,
        tags: values?.categoryTags || [],
        language: values.language,
        isFree: values.isFree,
        featured: values?.featured,
      };
      switch (action) {
        case "create":
          var response = await ContentService.createContent(body);
          form.resetFields();
          break;
        case "update":
          var response = await ContentService.updateContent(
            detailData.contentId,
            body
          );

          break;
      }
      message.success("İşlem Başarılı!");
    } catch (error) {
      console.log(error);
      message.error(JSON.stringify(error?.msgDetail || error));
    } finally {
      setLoadingBtn(false);
    }
  };
  useEffect(() => {
    if (detailData) {
      form.setFields([
        { name: "categoryId", value: detailData?.category?.categoryId },
        { name: "categoryTags", value: detailData.tags },
        {
          name: "contentOwnerId",
          value: detailData.contentOwner.contentOwnerId || "HEALMEUP",
        },
        { name: "fullContentUrl", value: detailData.content.fullContentUrl },
        {
          name: "previewContentUrl",
          value: detailData.content.previewContentUrl,
        },

        {
          name: "listenLengthInMinutes",
          value: Number(detailData.content.listenLengthInMinutes),
        },
        {
          name: "watchLengthInMinutes",
          value: Number(detailData.content.watchLengthInMinutes),
        },

        { name: "summary", value: detailData.summary },
        { name: "thumbnailUrl", value: detailData.thumbnailUrl },
        { name: "title", value: detailData.title },

        //ARTICLE
        {
          name: "blog",
          value: {
            blocks: detailData.content.fullBlocks,
            text: detailData.content.fullText,
          },
        },
        {
          name: "freeBlog",
          value: {
            blocks: detailData.content.previewBlocks,
            text: detailData.content.previewText,
          },
        },
        {
          name: "featured",
          value: detailData?.featured || false,
        },
        {
          name: "readLengthInMinutes",
          value: Number(detailData.content.readLengthInMinutes),
        },
        //ARTICLE

        { name: "language", value: detailData?.language },
        { name: "isFree", value: detailData?.free },
        {
          name: "contentStatus",
          value: detailData?.contentStatus === "DRAFT" ? false : true,
        },
      ]);
    }
  }, []);
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      scrollToFirstError
      style={{ marginBottom: 50 }}
    >
      {contents[contentType].component}
      <ContentDetail />
      <ContentSettings contentLanguage={detailData?.language || "TR"} />
      <Affix offsetBottom={0}>
        <Form.Item>
          <Button
            loading={loadingBtn}
            size="large"
            className="w-100"
            htmlType="submit"
            type="primary"
            style={{ marginBottom: 50 }}
          >
            {action === "create" ? "Ekle" : "Düzenle"}
          </Button>
        </Form.Item>
      </Affix>
    </Form>
  );
}
