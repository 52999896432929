import React, { useMemo, useCallback, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

import { Form, Input, Skeleton } from "antd";
import CustomSpinner from "./Spinner";
const AgGridTable = (props) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const gridRef = useRef();

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: props.height || "85%", width: "100%" }),
    []
  );

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    ...props.defaultColDef,
  };
  const localeText = {
    searchOoo: "Ara",
    filters: "Filtreler",
    selectAll: "(Hepsini Seç)",
    cut: "Kes",
    copy: "Kopyala",
    paste: "Yapıştır",
    copyWithHeaders: "Başlıklar ile kopyala",
    copyWithGroupHeaders: "Grup başlığı ile kopyala",
    pinColumn: "Alanı işaretle",
    noPin: "Yok",
    pinLeft: "Sola işaretle",
    pinRight: "Sağa işaretle",
    autosizeThiscolumn: "Bu alanı otomatik genişlet",
    autosizeAllColumns: "Bütün alanı otomatik genişlet",
    resetColumns: "Alanı sıfırla",
    export: "Dışarı Aktar",
    csvExport: ".csv indir",
    excelExport: "Excel indir",
    columns: "Kolonlar",
    pageSizeSelectorLabel: "Görüntüle",
    page: "Sayfa",
  };
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef?.current?.api?.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value || ""
    );
  }, []);

  const closeHeader = "closeHeader" in props;
  return (
    <div style={containerStyle}>
      {!closeHeader && (
        <div className="ag-header" style={props.headerStyle}>
          <Form.Item className="mb-0 p-0 col-4">
            <Input
              className="ag-search-input"
              label="Ara"
              type="text"
              id="filter-text-box"
              placeholder="Ara"
              onInput={() => onFilterTextBoxChanged()}
            />
          </Form.Item>
          <div className="ag-header-children ">{props.headerChildren}</div>
        </div>
      )}
      {props.infoTopChildren}
      <div
        className={`ag-theme-balham${
          theme === "default" ? "" : theme === "dark" ? "-dark" : ""
        }`}
        style={gridStyle}
      >
        {props.loading ? (
          <div className="column mt-5">
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        ) : (
          <AgGridReact
            onPaginationChanged={({ newPage }) => {
              if (!gridRef.current || !newPage) {
                return;
              }
              const currentPage =
                gridRef.current.api.paginationGetCurrentPage();
              if (props.getCurrentPage) {
                props.getCurrentPage(currentPage);
              }
            }}
            pagination={true}
            localeText={localeText}
            loadingOverlayComponent={() => <CustomSpinner />}
            noRowsOverlayComponent={() => "Veri bulunamadı."}
            ref={gridRef}
            getRowId={(params, i) => {
              return params.data.sortOrder;
            }}
            {...props}
            defaultColDef={defaultColDef}
            sideBar={{
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: true,
                  },
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
              hiddenByDefault: false,
            }}
            suppressMoveWhenRowDragging={true}
            onRowDragEnd={(event) => {}}
            // rowDragManaged={true}
          />
        )}
      </div>
    </div>
  );
};

export default AgGridTable;
