import axiosInstance from "./axiosConfig";
const api = {
  getCredits: ({ startDate }) => {
    const params = {
      page: 1,
      size: 100000000,
      startDate,
    };
    return axiosInstance.get(`/v1/sessions/credits/all`, {
      params,
    });
  },
  cancelCredit: (creditId) => {
    return axiosInstance.put(`/v1/sessions/credits/${creditId}/cancel`);
  },
};
export default api;
