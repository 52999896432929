import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Api
import { AuthService } from "../../api";
import { Input, Form, Button, message } from "antd";

import { UserOutlined, LockOutlined } from "@ant-design/icons";

import useToken from "../hooks/useToken";
const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { setToken } = useToken();
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await AuthService.login(values);
      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }
      setToken(response.data.data.token);
      navigate("/");
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  /**
 * {
    email: "aynurilhan@evimdekipsikolog.com",
    password: "password",
  }
 */
  return (
    <div className="row justify-content-center h-100 align-items-center h-80">
      <div className="col-md-6">
        <div className="authincation-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <h4 className="text-center mb-4 "> Hesabınızda oturum açın </h4>
                <Form
                  scrollToFirstError
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Email alanını doldurun.",
                      },
                      { type: "email", message: "Lütfen email Yazınız." },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="mr-2" />}
                      type="email"
                      name="email"
                      placeholder="merhaba@ornek.com"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Şifre"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Şifre alanını doldurun.",
                      },
                      { type: "password" },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="mr-2" />}
                      type="password"
                      name="password"
                      placeholder="Şifre"
                      size="large"
                    />
                  </Form.Item>
                  <div className="mb-3">
                    <Link to="/forgot-password"> Şifremi unuttum </Link>
                  </div>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="w-100"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                    >
                      Giriş Yap{" "}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
