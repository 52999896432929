import axiosInstance from "./axiosConfig";
const api = {
  getContents: (contentType) => {
    let url = "/v1/contents/all";
    if (!contentType) contentType = "VIDEO";

    return axiosInstance.get(url, { params: { contentType } });
  },
  createContent: (body) => {
    let url = "/v1/contents";

    return axiosInstance.post(url, body);
  },
  updateContent: (id, body) => {
    let url = `/v1/contents/${id}`;

    return axiosInstance.put(url, body);
  },
  getContentDetail: (id) => {
    let url = `/v1/contents/${id}/detail`;
    return axiosInstance.get(url);
  },
  deleteContent: (id) => {
    let url = `/v1/contents/${id}`;
    return axiosInstance.delete(url);
  },
};
export default api;
