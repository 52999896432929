import React, { useState } from "react";
import { Modal, Button, Form, DatePicker, Row, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionModals,
  setSessionDetail,
} from "../../../redux/sessionSlice";
import AgGridTable from "../../AgGridTable";
import dayjs from "dayjs";
import useSlots from "../../../hooks/useSlots";
export default function HourUpdateModal() {
  const [getExpertAvailabilities] = useSlots();
  const dispatch = useDispatch();
  const [sessionYearTime, setSessionYearTime] = useState();

  const isOpen = useSelector((state) => state.session.modals.sessionHourUpdate);

  const hourOptions = useSelector((state) => state.session.slot.slotHours);
  const sessionState = useSelector((state) => state.session.sessionDetail);

  const [columnDefs, _] = useState([
    {
      field: "disabled",
      headerName: "Durum",
      cellRenderer: (params) => {
        if (params.data.reservable) {
          return <Button type="primary">Uygun</Button>;
        }
        return (
          <Button type="primary" className="bg-red">
            Uygun değil
          </Button>
        );
      },
    },
    { field: "label", headerName: "Saat" },
  ]);

  const onChangeSessionTime = (value) => {
    setSessionYearTime(value);
    getExpertAvailabilities({
      expertId: sessionState.expertId,
      sessionTime: value,
    });
  };

  const onRowClicked = (params) => {
    if (!params.data.reservable) {
      message.error("Saat uygun değil.");
      return;
    }

    const [hour, min] = params.data.label
      .split(":")
      .map((item) => Number(item));
    const sessionCurrentTime = dayjs(
      !sessionYearTime ? sessionState.sessionTime : sessionYearTime
    )
      .set("hour", hour)
      .set("minute", min)
      .format("YYYY-MM-DDTHH:mm:00");

    dispatch(
      setSessionDetail({
        ...sessionState,
        hourInfo: params.data.label,
        sessionCurrentTime,
      })
    );
    dispatch(setSessionModals({ sessionHourUpdate: false }));
  };
  const getRowStyle = (params) => {
    let styles = {
      cursor: "pointer",
    };
    if (!params.data.reservable) {
      styles = {
        ...styles,
        cursor: "not-allowed",
      };
    }
    if (params.data.label === sessionState.hourInfo) {
      styles = {
        ...styles,
        background: "#E5E7E9",
      };
    }
    return styles;
  };
  return (
    <Modal
      centered=""
      onCancel={() => dispatch(setSessionModals({ sessionHourUpdate: false }))}
      width={1000}
      title="Saati Düzenle"
      open={isOpen}
      footer={false}
    >
      <AgGridTable
        noRowsOverlayComponent={() => "Veri bulunamadı."}
        onRowClicked={onRowClicked}
        getRowStyle={getRowStyle}
        rowData={hourOptions}
        columnDefs={columnDefs}
        sideBar={"filters"}
        height={300}
        rowHeight={40}
        headerChildren={
          <Form.Item
            className="mb-0"
            label="Tarih Seçiniz"
            name={"sessionTime"}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              name={"sessionTime"}
              onChange={onChangeSessionTime}
              defaultValue={dayjs(sessionState.sessionTime)}
              placeholder={"Seans Tarihini Seçiniz"}
            />
          </Form.Item>
        }
        infoTopChildren={
          hourOptions?.length ? (
            <Row className="mb-2 mt-4">
              <Row className="mr-3">
                Uygun
                <Button size="small" type="primary" className="ml-2">
                  {hourOptions.filter((item) => item.reservable).length || 0}
                </Button>
              </Row>
              <Row className="ml-2">
                Uygun Değil
                <Button size="small" type="primary" className="ml-2 bg-red">
                  {hourOptions.filter((item) => !item.reservable).length || 0}
                </Button>
              </Row>
            </Row>
          ) : (
            <></>
          )
        }
      />
    </Modal>
  );
}
