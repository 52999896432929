const addressJson = require("./address.json");
module.exports = {
  groupSessionsStatus: {
    OPEN: "Açık",
    CANCELLED: "İptal Edildi",
  },
  subsTypes: {
    MONTHLY: "Aylık",
    THREE_MONTH: "3 Aylık",
  },
  subsPaymentStatus: {
    INITIATED: "Başlatıldı",
    SUCCESSFUL: "Başarılı",
    FAILED: "Hata",
  },
  subsStatus: {
    INITIATED: "Başlatıldı",
    ACTIVE: "Aktif",
    CANCELLED: "Pasif",
    REFUNDED: "İade Edildi",
    EXPIRED: "Süresi Doldu",
  },
  promotionTypes: {
    DISCOUNT_RATE: "Oransal İndirim",
    AMOUNT_BASED: "Tutar İndirim",
  },
  meetingTypes: {
    MESSAGING: "Mesajlaşma",
    VIDEO_CALL: "Video Görüşme",
    VOICE_CALL: "Sesli Görüşme",
  },
  sessionCreditAvailabilityStatus: {
    AVAILABLE: "Hazır",
    EXPIRED: "Süresi Geçmiş",
    CONSUMED: "Harcandı",
    REFUNDED: "Iade Edildi",
  },
  sessionPaymentTypes: {
    CREDIT_CARD: "Kredi Kartı",
    CREDIT: "Kredi",
    FREE: "Ücretsiz",
  },
  days: {
    MONDAY: "Pazartesi",
    TUESDAY: "Salı",
    WEDNESDAY: "Çarşamba",
    THURSDAY: "Perşembe",
    FRIDAY: "Cuma",
    SATURDAY: "Cumartesi",
    SUNDAY: "Pazar",
  },
  hours: [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ],
  sessionTypes: {
    INDIVIDUAL_THERAPY: "Bireysel Terapi",
    COUPLE_THERAPY: "Çift Terapisi",
    FREE_THERAPY: "Ücretsiz ön görüşme",
  },
  sessionPackageOptions: {
    ONE: "Tek Seans",
    TRIPLE: "3'lü Seans",
    FIVE: "5'li Seans",
    TEN: "10'lu Paket",
  },
  sessionStatus: [
    {
      label: "Ödeme bekliyor",
      value: "WAITING_FOR_PAYMENT",
      className: "bg-yellow",
    },
    {
      label: "Seans başlaması bekliyor",
      value: "WAITING_FOR_START",
      className: "bg-warning",
    },
    {
      label: "Müşteri iptal etti",
      value: "CANCELED_BY_CUSTOMER",
      className: "bg-danger",
    },
    {
      label: "Uzman iptal etti",
      value: "CANCELED_BY_EXPERT",
      className: "bg-danger",
    },
    {
      label: "Oturum tamamlandı",
      value: "SESSION_FINISHED",
      className: "bg-purple",
    },
    { label: "Tamamlandı", value: "COMPLETED", className: "bg-success" },
  ],
  notificationTypes: [
    { value: "NEW_APPOINTMENT", label: "NA" },
    { value: "REMIND_APPOINTMENT", label: "RA" },
    { value: "REMIND_BUSINESS_FOR_WAITING_FOR_APPROVAL", label: "RBW" },
    { value: "APPOINTMENT_APPROVED", label: "AA" },
    { value: "APPOINTMENT_REJECTED_BY_CUSTOMER", label: "ARC" },
    { value: "APPOINTMENT_REJECTED_BY_BUSINESS", label: "ARB" },
    { value: "APPOINTMENT_CANCELED_BY_CUSTOMER", label: "ACC" },
    { value: "APPOINTMENT_CANCELED_BY_BUSINESS", label: "ACB" },
    { value: "APPOINTMENT_ASSESSED_BY_CUSTOMER", label: "AAC" },
    { value: "SESSION_CLOSE_TO_FINISH", label: "SCF" },
    { value: "ADDED_EXTRA_TIME", label: "AET" },
    { value: "NEW_FREE_SESSION", label: "NFS" },
    { value: "SUCCESSFUL_PAYMENT_NOTIFICATION", label: "SPN" },
    { value: "FAILED_PAYMENT_NOTIFICATION", label: "FPN" },
    { value: "BOOKING_UPDATED_NOTIFICATION_FOR_EXPERT", label: "UNF" },
    { value: "BOOKING_UPDATED_NOTIFICATION_FOR_CLIENT", label: "UNC" },
    { value: "BOOKING_REASSIGNED_NOTIFICATION_FOR_EXPERT", label: "BRF" },
    { value: "BOOKING_REASSIGNED_NOTIFICATION_FOR_CLIENT", label: "BRC" },
  ],
  commentStatus: [
    {
      value: "WAITING_FOR_APPROVAL",
      label: "Onay bekliyor",
      className: "bg-warning",
    },
    {
      value: "APPROVED",
      label: "Onaylandı",
      className: "bg-success",
    },
    {
      value: "REJECTED",
      label: "Reddedildi",
      className: "bg-danger",
    },
  ],
  addressJson,
};
