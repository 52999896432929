import React from "react";
import { useParams } from "react-router-dom";
import { DetailHeader } from "../../components";
import { Dropdown, Button, Typography, message } from "antd";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { CreditService } from "../../../api";

export default function CreditDetail() {
  const { creditId } = useParams();
  const ActionComponent = () => {
    const actionCredit = async (action) => {
      try {
        switch (action) {
          case "cancel":
            var response = await CreditService.cancelCredit(creditId);
          default:
            break;
        }
        if (response.data.status === "failed") {
          message.error(response.data.msgDetail);
          return;
        }
        message.success("İşlem Başarılı!");
      } catch (error) {
        message.error(JSON.stringify(error));
      }
    };
    return (
      <Dropdown
        placement="bottomRight"
        menu={{
          items: [
            {
              key: "delete",
              label: (
                <Button
                  size="large"
                  onClick={() => actionCredit("cancel")}
                  type="text"
                  className="text-red"
                >
                  <CloseOutlined />
                  Krediyi İptal Et
                </Button>
              ),
            },
          ],
        }}
      >
        <Button type="text">
          <Typography>
            İşlemler
            <DownOutlined className="ml-2" />
          </Typography>
        </Button>
      </Dropdown>
    );
  };
  return (
    <>
      <DetailHeader actionComponent={<ActionComponent />} />
      CreditDetail,{creditId}
    </>
  );
}
