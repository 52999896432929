import {
  SettingOutlined,
  SnippetsOutlined,
  QrcodeOutlined,
  GiftOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import Dashobard from "../pages/Dashboard";
import Profile from "../pages/Profile";

//User
import Users from "../pages/Users";

//Expert
import Experts from "../pages/Experts";
import NewExpert from "../pages/Experts/NewExpert";
import ExpertDetail from "../pages/Experts/ExpertDetail";

//Session
import Sessions from "../pages/Sessions";

//Comment
import Comments from "../pages/Comments";

//Credit
import Credits from "../pages/Credits";

//Notification
import Notifications from "../pages/Notifications";
import NotificationDetail from "../pages/Notifications/NotificationDetail";
import NewNotification from "../pages/Notifications/NewNotification";
import SessionDetail from "../pages/Sessions/SessionDetail";
import CommentDetail from "../pages/Comments/CommentDetail";
import UserDetail from "../pages/Users/UserDetail";
import Settings from "../pages/Settings";
import CreditDetail from "../pages/Credits/CreditDetail";

//Content
import Contents from "../pages/Content";
import ContentDetail from "../pages/Content/ContentDetail";
import NewContent from "../pages/Content/NewContent";

//Category
import Category from "../pages/Category";
import CategoryDetail from "../pages/Category/CategoryDetail";
import NewCategory from "../pages/Category/NewCategory";

//GroupSessions
import GroupSessions from "../pages/GroupSessions";
import NewGroupSession from "../pages/GroupSessions/NewGroupSession";

//PromotionCode
import PromotionCode from "../pages/PromotionCode";
import NewPromotionCode from "../pages/PromotionCode/NewPromotionCode";
const routes = [
  {
    name: "Dashboard",
    path: "dashboard",
    exact: true,
    protected: true,
    component: Dashobard,
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 21 21"
      >
        <g
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M9 4.025A7.5 7.5 0 1 0 16.975 12H9V4.025Z" />
          <path d="M12.5 1c-.169 0-.334.014-.5.025V9h7.975c.011-.166.025-.331.025-.5A7.5 7.5 0 0 0 12.5 1Z" />
        </g>
      </svg>
    ),
  },
  {
    name: "Profil",
    path: "profile",
    exact: true,
    protected: true,
    component: Profile,
  },
  {
    name: "Danışanlar",
    path: "users",
    exact: true,
    protected: true,
    component: Users,
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4.333 6.764a3 3 0 1 1 3.141-5.023M2.5 16H1v-2a4 4 0 0 1 4-4m7.379-8.121a3 3 0 1 1 2.976 5M15 10a4 4 0 0 1 4 4v2h-1.761M13 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z"
        />
      </svg>
    ),
  },
  {
    name: "Danışan Detay",
    path: "user-detail/:userId",
    exact: true,
    protected: true,
    component: UserDetail,
  },
  {
    name: "Uzmanlar",
    path: "experts",
    exact: true,
    protected: true,
    component: Experts,
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 18"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 3a3 3 0 1 1-1.614 5.53M15 12a4 4 0 0 1 4 4v1h-3.348M10 4.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM5 11h3a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
        />
      </svg>
    ),
  },
  {
    name: "Promosyon",
    path: "promotions",
    exact: true,
    protected: true,
    component: PromotionCode,
    icon: (
      <GiftOutlined
        style={{
          fontSize: 50,
        }}
      />
    ),
  },
  {
    name: "Yeni Promosyon Kodu",
    path: "new-promotion-code",
    exact: true,
    protected: true,
    component: NewPromotionCode,
  },

  {
    name: "İçerikler",
    path: "contents",
    exact: true,
    protected: true,
    component: Contents,
    icon: (
      <SnippetsOutlined
        style={{
          fontSize: 50,
        }}
      />
    ),
  },

  {
    name: "Yeni İçerik ekle",
    path: "new-content/:contentType",
    exact: true,
    protected: true,
    component: NewContent,
  },
  {
    name: "İçerik Detay",
    path: "content-detail/:contentId/:contentType",
    exact: true,
    protected: true,
    component: ContentDetail,
  },
  {
    name: "Kategoriler",
    path: "category",
    exact: true,
    protected: true,
    component: Category,
    icon: (
      <QrcodeOutlined
        style={{
          fontSize: 50,
        }}
      />
    ),
  },
  {
    name: "Yeni Kategori Ekle",
    path: "new-category",
    exact: true,
    protected: true,
    component: NewCategory,
  },
  {
    name: "Kategori Detay",
    path: "category-detail/:categoryId",
    exact: true,
    protected: true,
    component: CategoryDetail,
  },
  {
    name: "Yeni Uzman Ekle",
    path: "new-expert",
    exact: true,
    protected: true,
    component: NewExpert,
  },
  {
    name: "Uzman Detay",
    path: "expert-detail/:expertId",
    exact: true,
    protected: true,
    component: ExpertDetail,
  },
  {
    name: "Seanslar",
    path: "sessions",
    exact: true,
    protected: true,
    component: Sessions,
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 1H1m14 18H1m2 0v-4.333a2 2 0 0 1 .4-1.2L5.55 10.6a1 1 0 0 0 0-1.2L3.4 6.533a2 2 0 0 1-.4-1.2V1h10v4.333a2 2 0 0 1-.4 1.2L10.45 9.4a1 1 0 0 0 0 1.2l2.15 2.867a2 2 0 0 1 .4 1.2V19H3Z"
        />
      </svg>
    ),
  },
  {
    name: "Grup Seanslar",
    path: "group-sessions",
    exact: true,
    protected: true,
    component: GroupSessions,
    icon: (
      <GroupOutlined
        style={{
          fontSize: 50,
        }}
      />
    ),
  },
  {
    name: "Yeni Grup Seansı",
    path: "new-group-session",
    exact: true,
    protected: true,
    component: NewGroupSession,
  },
  {
    name: "Yorumlar",
    path: "comments",
    exact: true,
    protected: true,
    component: Comments,
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 18"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 5h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-2v3l-4-3H8m4-13H2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h2v3l4-3h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
        />
      </svg>
    ),
  },
  {
    name: "Yorum Detay",
    path: "comment-detail/:commentId",
    exact: true,
    protected: true,
    component: CommentDetail,
  },
  {
    name: "Krediler",
    path: "credits",
    exact: true,
    protected: true,
    component: Credits,
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 14"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 9h2m3 0h5M1 5h18M2 1h16a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"
        />
      </svg>
    ),
  },
  {
    name: "Kredi Detay",
    path: "credit-detail/:creditId",
    exact: true,
    protected: true,
    component: CreditDetail,
  },
  {
    name: "Bildirim Şablonları",
    path: "notifications",
    exact: true,
    protected: true,
    component: Notifications,
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 21"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8 3.464V1.1m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175C15 15.4 15 16 14.462 16H1.538C1 16 1 15.4 1 14.807c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 8 3.464ZM4.54 16a3.48 3.48 0 0 0 6.92 0H4.54Z"
        />
      </svg>
    ),
  },
  {
    name: "Bildirim Şablonu Detay",
    path: "notification-detail/:templateId",
    exact: true,
    protected: true,
    component: NotificationDetail,
  },
  {
    name: "Bildirim Şablonu Ekle",
    path: "new-notification",
    exact: true,
    protected: true,
    component: NewNotification,
  },
  {
    name: "Seans Detay",
    path: "session-detail/:sessionId",
    exact: true,
    protected: true,
    component: SessionDetail,
  },
  {
    name: "Ayarlar",
    path: "settings",
    exact: true,
    protected: true,
    component: Settings,
    icon: <SettingOutlined />,
  },
];
export default routes;
