import React from "react";
import PreviewWindow from "../../images/mobile-window.png";
export default function MobilePreview({ html, blocksLength }) {
  return (
    <div className=" h-100 w-100 d-flex align-items-center justify-content-center">
      <div
        className=""
        style={{
          height: "35rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {blocksLength ? (
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className=""
            style={{
              position: "absolute",
              height: "90%",
              width: "100%",
              overflow: "auto",
            }}
          />
        ) : (
          <div
            className=""
            style={{
              position: "absolute",
              width: "100%",
              overflow: "auto",
              textAlign: "center",
            }}
          >
            Veri Bulunamadı
          </div>
        )}
        <img
          className="w-100 h-100"
          style={{
            border: "1px solid #CCD1D1",
            borderRadius: "15px",
          }}
          src={PreviewWindow}
          alt=""
        />
      </div>
    </div>
  );
}
