import axiosInstance from "./axiosConfig";
const api = {
  getCategories: () => {
    let url = "/v1/contents/categories/all";

    return axiosInstance.get(url);
  },
  createCategory: (values) => {
    let url = "/v1/contents/categories";

    return axiosInstance.post(url, values);
  },
  updateCategory: (id, body) => {
    let url = "/v1/contents/categories/" + id;

    return axiosInstance.put(url, body);
  },
  deleteCategory: (id) => {
    let url = "/v1/contents/categories/" + id;

    return axiosInstance.delete(url);
  },
};
export default api;
