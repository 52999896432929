import React from "react";
import DetailHeader from "../../components/DetailHeader";
import ExpertForm from "../../components/Forms/ProfileExpert";
const NewExpert = () => {
  return (
    <>
      <DetailHeader />
      <ExpertForm action="create" />
    </>
  );
};
export default NewExpert;
