import axiosInstance from "./axiosConfig";
const api = {
  getGroupSessions: () => {
    let url = "/v1/group-sessions";

    return axiosInstance.get(url);
  },
  createGroupSession: (body) => {
    let url = "/v1/group-sessions";
    return axiosInstance.post(url, body);
  },
};
export default api;
