import { FormItem } from ".";
import { Card, Space } from "antd";
import { useSelector } from "react-redux";
const ProfileItem = () => {
  const expertDetail = useSelector((state) => state.newExpert.expertDetail);
  const generatePhonForInitial = (phone) => {
    try {
      const stringPhone = phone?.split("+90")[1];

      return stringPhone;
    } catch (error) {
      console.log(error);
      return "";
    }
  };
  const profileFormItems = [
    {
      label: "Fotoğraf",
      type: "text",
      rules: [
        {
          type: "url",
          message: "URL formatına uygun Yazınız.",
        },
      ],
      name: "profilePictureUrl",
      defaultValue: expertDetail.profilePictureUrl,
    },
    {
      label: "İsim",
      type: "text",
      rules: [],
      name: "firstName",
      defaultValue: expertDetail.firstName,
    },
    {
      label: "Soy İsim",
      rules: [],
      type: "text",
      name: "lastName",
      defaultValue: expertDetail.lastName,
    },
    {
      label: "TC Kimlik",
      rules: [],
      type: "text",
      name: "identityNumber",
      defaultValue: expertDetail.identityNumber,
    },
    {
      label: "Telefon",
      rules: [],
      type: "text",
      defaultValue: expertDetail.phone,
      name: "phone",
    },
    {
      label: "Email",
      rules: [
        {
          type: "email",
          message: "Email formatına uygun Yazınız.",
        },
      ],
      type: "email",
      name: "email",
      defaultValue: expertDetail.email,
    },
    {
      label: "Eğitim",
      rules: [],
      type: "text",
      name: "education",
      defaultValue: expertDetail.education,
    },
    {
      label: "Özgeçmiş",
      rules: [],
      type: "text",
      name: "resumeDescription",
      component: "textarea",
      defaultValue: expertDetail.resumeDescription || expertDetail.resume,
    },
  ];
  return (
    <Card
      className="mb-3"
      title="Profil Detay"
      bodyStyle={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
    >
      <div className="row">
        {profileFormItems.map((formItem, key) => (
          <FormItem formItem={formItem} key={key} />
        ))}
      </div>
    </Card>
  );
};
export default ProfileItem;
