import React from "react";
import AgGridTable from "../../components/AgGridTable";
import { Skeleton } from "antd";
import { UserService } from "../../../api";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { usersListColumn } from "../../components/Columns";

const Users = () => {
  const { queryData, queryLoading } = useQuery({
    service: UserService.getUsers,
  });
  const navigate = useNavigate();
  const onRowClicked = (params) => {
    navigate(`/user-detail/${params.data.userId}`);
  };
  return (
    <AgGridTable
      loading={queryLoading}
      onRowClicked={onRowClicked}
      rowData={queryData?.users}
      columnDefs={usersListColumn}
      sideBar={"filters"}
    />
  );
};

export default Users;
