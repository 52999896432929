import React, { useState, useEffect } from "react";
import { Card, Form, Input, message } from "antd";
import { useParams } from "react-router-dom";
import { UserService } from "../../../api";
export default function UserDetail() {
  const { userId } = useParams();
  const [userDetail, setUserDetail] = useState();
  const [loading, setLoading] = useState(true);

  const getUserDetail = async () => {
    try {
      const response = await UserService.getUsers();
      const user = response.data.data.users.find(
        (item) => item.userId === userId
      );
      setUserDetail(user);
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserDetail();
  }, []);
  return (
    <Card title="Danışan Profil" loading={loading || !userDetail}>
      <Form layout="vertical">
        <div className="row">
          <Form.Item
            label="İsim Soyisim"
            initialValue={userDetail?.fullName}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input size="large" value={userDetail?.fullName} disabled={true} />
          </Form.Item>
          <Form.Item
            label="Email"
            initialValue={userDetail?.email}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input size="large" value={userDetail?.email} disabled={true} />
          </Form.Item>
          <Form.Item
            label="Kullanıcı Adı"
            initialValue={userDetail?.nickname}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input size="large" value={userDetail?.nickname} disabled={true} />
          </Form.Item>
          <Form.Item
            label="Telefon"
            initialValue={userDetail?.phone}
            className="col-12 col-md-6 col-lg-3"
          >
            <Input size="large" value={userDetail?.phone} disabled={true} />
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
}
