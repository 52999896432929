import React, { useState, useEffect } from "react";
import { DetailHeader } from "../../components";

import { Input, Card, message, Form, Dropdown, Button, Typography } from "antd";
import { CommentService } from "../../../api";
import { useParams, useNavigate } from "react-router-dom";
import { DownOutlined, CheckOutlined } from "@ant-design/icons";
const CommentDetail = () => {
  const [comment, setComment] = useState({});
  const [loading, setLoading] = useState(true);
  const { commentId } = useParams();
  const getCommentDetail = async () => {
    try {
      const response = await CommentService.getComment(commentId);
      setComment(response.data.data);
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommentDetail();
  }, []);

  const ActionComponent = () => {
    const actionComment = async (action) => {
      try {
        switch (action) {
          case "approve":
            var response = await CommentService.approveComment(commentId);
            message.success("İşlem Başarılı", 2);
            break;

          default:
            break;
        }
      } catch (error) {
        message.error(JSON.stringify(error));
      }
    };
    return (
      <Dropdown
        placement="bottomRight"
        menu={{
          items: [
            {
              key: "delete",
              label: (
                <Button
                  size="large"
                  onClick={() => actionComment("approve")}
                  type="text"
                >
                  <CheckOutlined />
                  Yorumu Onayla
                </Button>
              ),
            },
          ],
        }}
      >
        <Button type="text">
          <Typography>
            İşlemler
            <DownOutlined className="ml-2" />
          </Typography>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <DetailHeader actionComponent={<ActionComponent />} />
      <Card loading={loading}>
        <Form layout="vertical">
          <div className="column">
            <Form.Item label="Durum" className="col-12 col-md-6 col-lg-3">
              <Input disabled value={""} />
            </Form.Item>
            <Form.Item label="Yorum Yapan" className="col-12 col-md-6 col-lg-3">
              <Input disabled value={comment.clientNickname} />
            </Form.Item>
            <Form.Item label="Yorum" className="col-12 col-md-6 col-lg-3">
              <Input.TextArea disabled value={comment.comment} />
            </Form.Item>
          </div>
        </Form>
      </Card>
    </>
  );
};
export default CommentDetail;
