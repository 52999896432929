import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import { Button } from "antd";
const Nav = () => {
  return (
    <Fragment>
      {/* <div className="action-bottom">
        <Button size="large" className="w-100" htmlType="submit" type="primary">
          Ekle
        </Button>
      </div> */}
      <NavHader />
      <SideBar />
      <Header />
    </Fragment>
  );
};

export default Nav;
