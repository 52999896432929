import React, { useEffect, useRef, useCallback, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import FontSizeTool from "editorjs-inline-font-size-tool";
import InlineImage from "editorjs-inline-image";
import FontFamily from "editorjs-inline-font-family-tool";
import Marker from "@editorjs/marker";
import RawTool from "@editorjs/raw";
import edjsHTMLCon from "../../utils/edjsHTMLCon";
import { Drawer, Button, Modal, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import MobilePreview from "./MobilePreview";
export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  list: List,
  Marker: {
    class: Marker,
  },
  fontFamily: FontFamily,
  fontSize: FontSizeTool,
  raw: {
    class: RawTool,
    config: {
      placeholder: "HTML Kodlarını Giriniz",
    },
  },
  image: {
    class: InlineImage,
    inlineToolbar: true,
    config: {
      embed: {
        display: true,
      },
      caption: false,
    },
  },
};
const ReactEditorJS = createReactEditorJS();
export default function EditorJS(props) {
  const [previewModal, setPreviewModal] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [html, setHtml] = useState("");
  const [blocksLength, setBlocksLength] = useState(0);

  const editorCore = useRef(null);
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);
  const onFinish = () => {
    props.setModal(false);
  };
  const onClickPreview = async () => {
    try {
      setPreviewLoading(true);
      const data = await editorCore.current.save();
      const html = edjsHTMLCon(data.blocks);
      setHtml(html);
      setBlocksLength(data.blocks?.length || 0);
      setPreviewModal(true);
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setPreviewLoading(false);
    }
  };
  return (
    <>
      <Modal
        title={props.title}
        open={props.openModal}
        onCancel={() => props.setModal(false)}
        width={600}
        centered
        cancelText="İptal"
        footer={false}
      >
        <ReactEditorJS
          defaultValue={{
            time: 1635603431943,
            blocks: props?.defaultBlocks || [],
          }}
          onInitialize={handleInitialize}
          i18n={{
            messages: {
              ui: {
                blockTunes: {
                  toggler: {
                    "Click to tune": "Blok Düzenle",
                    "or drag to move": "или перетащите",
                    "Click to delete": "Emin misin?",
                  },
                },
                inlineToolbar: {
                  converter: {
                    "Convert to": "Dönüştür",
                  },
                },
                toolbar: {
                  toolbox: {
                    Add: "Ekle",
                    Filter: "test",
                  },
                },
              },
              tools: {
                warning: {
                  Title: "Başlık",
                  Message: "Mesaj",
                },

                link: {
                  "Add a link": "Link Ekle",
                },

                stub: {
                  "The block can not be displayed correctly.":
                    "Blok doğru şekilde görüntülenemiyor.",
                },
              },
              blockTunes: {
                delete: {
                  Delete: "Sil",
                },
                moveUp: {
                  "Move up": "Yukarı Hareket Ettir",
                },
                moveDown: {
                  "Move down": "Aşağı Hareket Ettir",
                },
              },

              toolNames: {
                InlineImage: "Fotoğraf Link",
                Text: "Yazı",
                Heading: "Başlık",
                List: "Liste",
                Warning: "Hata",
                Checklist: "Kontrol Listesi",
                Quote: "Alıntı",
                Code: "Kod",
                Delimiter: "Sınırlayıcı",
                "Raw HTML": "HTML",
                Table: "Tablo",
                Link: "Link",
                Marker: "İşaretleyici",
                Bold: "Kalın",
                Italic: "Italic",
                InlineCode: "Satır içi kod",
              },
            },
          }}
          tools={EDITOR_JS_TOOLS}
          style={{
            width: 300,
          }}
          onChange={async (api, blocks) => {
            const data = await editorCore.current.save();
            props.form.setFieldValue(props.name, {
              blocks: data.blocks,
            });
          }}
          {...props}
        />{" "}
        <div className="footer-editorjs">
          <Button
            className="w-100"
            onClick={onFinish}
            size="large"
            type="primary"
          >
            Kaydet
          </Button>
          <Button
            className="w-100"
            onClick={onClickPreview}
            size="large"
            loading={previewLoading}
          >
            Ön İzleme <EyeOutlined />
          </Button>
        </div>
      </Modal>

      <Drawer
        zIndex={3800}
        title={`${props.title} Ön İzleme`}
        open={previewModal}
        onClose={() => setPreviewModal(false)}
        width={600}
        centered
        cancelText="İptal"
        footer={false}
      >
        <MobilePreview html={html} blocksLength={blocksLength} />
      </Drawer>
    </>
  );
}
