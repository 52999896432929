import { Icon } from "@iconify/react";
function CustomSpinner(props) {
  return (
    <Icon
      icon="svg-spinners:pulse"
      style={{ fontSize: "30px", color: props?.color || "#01c897" }}
      {...props}
    />
  );
}

export default CustomSpinner;
