import { DetailHeader } from "../../components";
import GroupSessionForm from "../../components/Forms/GroupSession";
const NewPromotionCode = () => {
  return (
    <>
      <DetailHeader />
      <GroupSessionForm />
    </>
  );
};
export default NewPromotionCode;
