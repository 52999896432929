import { message } from "antd";
import React, { useState, useEffect } from "react";

export default function useQuery({ service, params }) {
  const [queryLoading, setQueryLoading] = useState(true);
  const [queryData, setQueryData] = useState();
  const [serviceParams, setServiceParams] = useState({});

  const workService = async () => {
    try {
      const response = await service({ params: params || serviceParams });
      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }
      setQueryData(response.data.data);
    } catch (error) {
      console.log(error);
      message.error(JSON.stringify(error));
    } finally {
      setQueryLoading(false);
    }
  };
  useEffect(() => {
    workService();
  }, []);
  return { queryLoading, queryData };
}
