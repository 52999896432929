import React, { useState, useCallback, useEffect } from "react";
import AgGridTable from "../../components/AgGridTable";
import { CommentService } from "../../../api";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import constants from "../../../constants";
const Comments = () => {
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [columnDefs, _] = useState([
    {
      field: "status",
      headerName: "Durum",
      cellRenderer: (params) => {
        const currentStatus = constants.commentStatus.find(
          (item) => item.value === params.data.status
        );
        return (
          <Button
            type="primary"
            size="small"
            className={currentStatus.className}
          >
            {currentStatus?.label}
          </Button>
        );
      },
    },
    { field: "feedbackId", headerName: "ID" },
    { field: "overallRate", headerName: "Ortalama Puan" },
    { field: "createdAt", headerName: "Oluşturulma Tarihi" },
    { field: "ratedExpertId", headerName: "Yorum Yapılan Uzman ID" },
    { field: "clientId", headerName: "Yorum Yapan Kullanıcı ID" },
    { field: "clientNickname", headerName: "Yorum Yapan Kullanıcı Adı" },
    { field: "comment", headerName: "Yorum" },
  ]);

  const getComments = async () => {
    try {
      const response = await CommentService.getComment();
      const comments = response?.data?.data?.feedbackDetailList;
      if (comments) {
        setRowData(comments);
      }
    } catch (error) {
      message.error(JSON.stringify(error));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <AgGridTable
      loading={loading}
      onRowClicked={(params) =>
        navigate(`/comment-detail/${params.data.feedbackId}`)
      }
      rowData={rowData}
      columnDefs={columnDefs}
      sideBar={"filters"}
    />
  );
};

export default Comments;
