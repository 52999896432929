import { jwtDecode } from "jwt-decode";
const getUser = () => {
  try {
    let token = localStorage.getItem("token");
    const user = jwtDecode(JSON.parse(token));

    return user;
  } catch (error) {
    console.log(error);
    return {};
  }
};
export default getUser;
