import React, { useState } from "react";
import {
  Affix,
  Form,
  Button,
  Card,
  Input,
  ColorPicker,
  Switch,
  message,
} from "antd";
import { CategoryService } from "../../../../api";
export default function CategoryForm({ action, detailData }) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [form] = Form.useForm();
  const createForm = async (values) => {
    try {
      values.sortOrder = parseInt(values.sortOrder);
      values.valueTuple = {
        TR: values.titleTr,
        EN: values.titleEn,
      };
      values.descriptionTuple = {
        TR: values.descriptionTr,
        EN: values.descriptionEn,
      };
      values.description = "-";
      const response = await CategoryService.createCategory(values);
      if (response.data.status !== "failed") {
        message.success("İşlem Başarılı!");
        form.resetFields();
      } else {
        message.error({ content: response.data.msgDetail });
      }
    } catch (error) {
      message.error(JSON.stringify(error?.msgDetail || error));
    } finally {
      setLoadingBtn(false);
    }
  };
  const updateForm = async (values) => {
    try {
      const body = {
        valueTuple: {
          TR: values.titleTr,
          EN: values.titleEn,
        },
        secondaryColorCode: values.secondaryColorCode,
        tertiaryColorCode: values.secondaryColorCode,
        primaryColorCode: values.primaryColorCode,
        sortOrder: Number(values.sortOrder),
        description: "-",
        featured: values.featured,
        descriptionTuple: {
          TR: values.descriptionTr,
          EN: values.descriptionEn,
        },
      };

      const response = await CategoryService.updateCategory(
        detailData.categoryId,
        body
      );
      if (response.data.status !== "failed") {
        message.success("İşlem Başarılı!");
      } else {
        message.error({ content: response.data.msgDetail });
      }
    } catch (error) {
      console.log(error);
      message.error(JSON.stringify(error?.msgDetail || error));
    } finally {
      setLoadingBtn(false);
    }
  };
  const onFinish = async (values) => {
    setLoadingBtn(true);

    switch (action) {
      case "update":
        updateForm(values);
        break;
      case "create":
        createForm(values);
        break;
      default:
        break;
    }
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      scrollToFirstError
      style={{ marginBottom: 50 }}
    >
      <Card className="mb-3 " title="Detay Bilgiler">
        <div className="row">
          <Form.Item
            label="Başlık Türkçe"
            rules={[
              { required: true, message: "Lütfen Başlık alanını doldurun." },
            ]}
            className="col-12 col-md-6 col-lg-6"
            name={"titleTr"}
            initialValue={detailData?.valueTuple?.TR}
          >
            <Input
              placeholder="Başlık Yazınız"
              size="large"
              name="titleTr"
              addonBefore="TR"
            />
          </Form.Item>
          <Form.Item
            label="Başlık İngilizce"
            rules={[
              { required: true, message: "Lütfen Başlık alanını doldurun." },
            ]}
            className="col-12 col-md-6 col-lg-6"
            name={"titleEn"}
            initialValue={detailData?.valueTuple.EN}
          >
            <Input
              placeholder="Başlık Yazınız"
              size="large"
              name="titleEn"
              addonBefore="EN"
            />
          </Form.Item>
        </div>
      </Card>
      <Card className="mb-3 " title="Mobile Ayarlar">
        <div className="row">
          <Form.Item
            label="1. Renk"
            className="col-12 col-md-4 col-lg-4"
            name={"primaryColorCode"}
            rules={[
              { required: true, message: "Lütfen Renk alanını doldurun." },
            ]}
            initialValue={detailData?.primaryColorCode}
            getValueFromEvent={(color) => {
              return "#" + color.toHex();
            }}
          >
            <ColorPicker size="large" showText />
          </Form.Item>
          <Form.Item
            label="2. Renk"
            className="col-12 col-md-4 col-lg-4"
            name={"secondaryColorCode"}
            rules={[
              { required: true, message: "Lütfen Renk alanını doldurun." },
            ]}
            initialValue={detailData?.secondaryColorCode}
            getValueFromEvent={(color) => {
              return "#" + color.toHex();
            }}
          >
            <ColorPicker size="large" showText />
          </Form.Item>
          <Form.Item
            label="3. Renk"
            className="col-12 col-md-4 col-lg-4"
            name={"tertiaryColorCode"}
            rules={[
              { required: true, message: "Lütfen Renk alanını doldurun." },
            ]}
            initialValue={detailData?.tertiaryColorCode}
            getValueFromEvent={(color) => {
              return "#" + color.toHex();
            }}
          >
            <ColorPicker size="large" showText />
          </Form.Item>
        </div>
        <div className="row">
          <Form.Item
            initialValue={detailData?.sortOrder}
            label="Sıralama"
            className="col-12 col-md-4 col-lg-4"
            name={"sortOrder"}
            rules={[
              {
                required: true,
                message: "Lütfen Sıralama alanını doldurun.",
              },
            ]}
          >
            <Input
              placeholder="Sıralama Yazınız"
              size="large"
              name="sortOrder"
              type="number"
            />
          </Form.Item>
        </div>
        <div className="row">
          <Form.Item
            label="Kullanıcı önerisi"
            name={"featured"}
            valuePropName="checked"
            className="col-12 col-md-6 col-lg-3"
            initialValue={detailData?.featured || false}
          >
            <Switch
              defaultValue={false}
              value={false}
              size="large"
              name={"featured"}
            />
          </Form.Item>
        </div>
        <div className="row">
          <Form.Item
            label="TR Açıklama"
            className="col-12 col-md-4 col-lg-4"
            name={"descriptionTr"}
            initialValue={detailData?.descriptionTuple?.TR}
            rules={[
              {
                required: true,
                message: "Lütfen Açıklama alanını doldurun.",
              },
            ]}
          >
            <Input.TextArea
              placeholder="Türkçe Açıklama Yazınız"
              size="large"
              name="descriptionTr"
            />
          </Form.Item>
          <Form.Item
            label="EN Açıklama"
            className="col-12 col-md-4 col-lg-4"
            name={"descriptionEn"}
            initialValue={detailData?.descriptionTuple?.EN}
            rules={[
              {
                required: true,
                message: "Lütfen Açıklama alanını doldurun.",
              },
            ]}
          >
            <Input.TextArea
              placeholder="İngilizce Açıklama Yazınız"
              size="large"
              name="descriptionEn"
            />
          </Form.Item>
        </div>
      </Card>

      <Affix offsetBottom={0}>
        <Form.Item>
          <Button
            loading={loadingBtn}
            size="large"
            className="w-100"
            htmlType="submit"
            type="primary"
            style={{ marginBottom: 50 }}
          >
            {action === "update" ? "Düzenle" : "Ekle"}
          </Button>
        </Form.Item>
      </Affix>
    </Form>
  );
}
