import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import RenewPassword from "../pages/RenewPassword";

const routes = [
  {
    name: "Giriş Yap",
    path: "login",
    exact: true,
    protected: true,
    component: Login,
  },
  {
    name: "Şifremi Unuttum",
    path: "forgot-password",
    exact: true,
    protected: true,
    component: ForgotPassword,
  },
  {
    name: "Yeni Şifre",
    path: "renew-password",
    exact: true,
    protected: true,
    component: RenewPassword,
  },
];
export default routes;
