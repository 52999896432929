import React, { useState, useEffect } from "react";
import { DetailHeader } from "../../components";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import SessionForm from "../../components/Forms/Session";
import {
  Button,
  Card,
  message,
  Dropdown,
  Skeleton,
  Badge,
  Descriptions,
  Row,
  Tabs,
  Typography,
} from "antd";
import { SessionService } from "../../../api";
import { useParams, useNavigate } from "react-router-dom";
import {
  SwapOutlined,
  CheckOutlined,
  DownOutlined,
  CopyOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setSessionDetail } from "../../redux/sessionSlice";
import constants from "../../../constants";
import { useCountdown } from "../../hooks/useCountDow";
import dayjs from "dayjs";
import { sessionEndTarget } from "../../../services";
const ActionComponent = () => {
  const sessionState = useSelector((state) => state.session.sessionDetail);
  const actionSession = async (action) => {
    try {
      switch (action) {
        case "approve":
          var response = await SessionService.completeSession(
            {},
            sessionState.sessionId
          );
          break;

        case "waiting":
          var response = await SessionService.waitingForStart(
            sessionState.sessionId
          );
          break;
        case "reject":
          var response = await SessionService.rejectSession(
            {},
            sessionState.sessionId
          );
          const fireBaseResponse = await sessionEndTarget(
            sessionState.sessionId
          );
          break;

        default:
          break;
      }
      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }
      message.success("İşlem Başarılı");
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };
  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        items: [
          {
            key: "accept",
            label: (
              <Button
                size="large"
                onClick={() => actionSession("approve")}
                type="text"
              >
                <CheckOutlined />
                Seansı Onayla
              </Button>
            ),
          },
          {
            key: "waiting",
            label: (
              <Button
                size="large"
                onClick={() => actionSession("waiting")}
                type="text"
                className="text-orange"
              >
                <CloseOutlined />
                Seansı Bekliyora Çek
              </Button>
            ),
          },
          {
            key: "reject",
            label: (
              <Button
                size="large"
                onClick={() => actionSession("reject")}
                type="text"
                className="text-red"
              >
                <CloseOutlined />
                Seansı İptal Et
              </Button>
            ),
          },
        ],
      }}
    >
      <Button type="text">
        <Typography>
          İşlemler
          <DownOutlined className="ml-2" />
        </Typography>
      </Button>
    </Dropdown>
  );
};
const ShowCounter = ({ days, hours, minutes, seconds }) => {
  const DateTimeDisplay = ({ value, type }) => {
    return (
      <div className="d-flex flex-column align-items-center m-3">
        <span>{type}</span>
        <p>{value}</p>
      </div>
    );
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <DateTimeDisplay value={days} type={"Gün"} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={"Saat"} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={"Dakika"} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"Saniye "} />
      </div>
    </>
  );
};
const SessionConversationDetail = () => {
  const navigate = useNavigate();
  const sessionState = useSelector((state) => state.session.sessionDetail);
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [days, hours, minutes, seconds, setCountDownDate] = useCountdown(
    sessionState?.sessionTime
  );
  const findStatus = constants.sessionStatus.find(
    (item) => item.value === sessionState?.sessionStatus?.type
  );
  const isMobile = window.innerWidth <= 800;
  return (
    <>
      <Card className="mb-3 col-12 col-md-12 col-lg-12" hidden={isMobile}>
        <div className="d-flex align-items-center justify-content-evenly">
          <Badge count="Uzman" color="#01c897">
            <Button
              onClick={() =>
                navigate(`/expert-detail/${sessionState.expertId}`)
              }
              className="sesion-rounded "
            >
              {sessionState.expertFullName}
            </Button>
          </Badge>
          <div className="d-flex flex-column align-items-center">
            <Button
              type="primary"
              size="small"
              className={findStatus.className}
            >
              {sessionState.readableTimeInfo} {"  "} {findStatus.label}
            </Button>
            <SwapOutlined className="mt-3 " />
            {days + hours + minutes + seconds <= 0 ? (
              <></>
            ) : (
              <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            )}
          </div>
          <Badge count="Danışan" color="#01c897">
            <Button
              onClick={() =>
                navigate(`/user-detail/${sessionState.client.clientId}`)
              }
              className="sesion-rounded "
            >
              {sessionState.client.fullName || sessionState.client.nickname}
            </Button>
          </Badge>
        </div>
        <div className="row">
          <Descriptions
            className="mt-3 col-6"
            bordered
            column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
            layout={window.innerWidth < 1000 ? "vertical" : "horizontal"}
            items={[
              {
                key: "0",
                label: "ID",
                children: (
                  <Row>
                    {sessionState.expertId}
                    <Button type="primary" size="small" className="ml-2">
                      <CopyOutlined
                        classID="mr-3"
                        onClick={() => {
                          copyToClipboard(sessionState.expertId);
                          message.success("ID kopyalandı.");
                        }}
                      />
                    </Button>
                  </Row>
                ),
              },
              {
                key: "1",
                label: "İsim Soyisim",
                children: sessionState.expertFullName,
              },

              {
                key: "2",
                label: "Uzmanlık Alanı",
                children: sessionState.titleType.text,
              },

              {
                key: "3",
                label: "Telefon",
                children: sessionState.expertPhone,
              },
            ]}
            size="small"
          />

          <Descriptions
            className="mt-3 col-6"
            bordered
            column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
            layout={window.innerWidth < 1000 ? "vertical" : "horizontal"}
            items={[
              {
                key: "0",
                label: "ID",
                children: (
                  <Row>
                    {sessionState.client.clientId}
                    <Button type="primary" size="small" className="ml-2">
                      <CopyOutlined
                        classID="mr-3"
                        onClick={() => {
                          copyToClipboard(sessionState.client.clientId);
                          message.success("ID kopyalandı.");
                        }}
                      />
                    </Button>
                  </Row>
                ),
              },
              {
                key: "1",
                label: "İsim Soyisim",
                children:
                  sessionState.client.fullName || sessionState.client.nickname,
              },
              {
                key: "2",
                label: "Telefon",
                children: sessionState.client.phone,
              },
            ]}
            size="small"
          />
        </div>
      </Card>
      <Card className="mb-3" hidden={!isMobile}>
        <div className="d-flex flex-column align-items-center mt-3">
          <Button type="primary" size="small" className={findStatus.className}>
            {sessionState.readableTimeInfo} {"  "} {findStatus.label}
          </Button>
          <SwapOutlined className="mt-3 " />
          {days + hours + minutes + seconds <= 0 ? (
            <></>
          ) : (
            <ShowCounter
              days={days}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          )}
        </div>
        <Badge count="Uzman" className="mt-5" color="#01c897">
          <Button
            onClick={() => navigate(`/expert-detail/${sessionState.expertId}`)}
            className="sesion-rounded "
          >
            {sessionState.expertFullName}
          </Button>
        </Badge>
        <Descriptions
          className="mt-3"
          bordered
          column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
          layout={window.innerWidth < 1000 ? "vertical" : "horizontal"}
          items={[
            {
              key: "0",
              label: "ID",
              children: (
                <Row>
                  {sessionState.expertId}
                  <Button type="primary" size="small" className="ml-2">
                    <CopyOutlined
                      classID="mr-3"
                      onClick={() => {
                        copyToClipboard(sessionState.expertId);
                        message.success("ID kopyalandı.");
                      }}
                    />
                  </Button>
                </Row>
              ),
            },
            {
              key: "1",
              label: "İsim Soyisim",
              children: sessionState.expertFullName,
            },

            {
              key: "2",
              label: "Uzmanlık Alanı",
              children: sessionState.titleType.text,
            },

            {
              key: "3",
              label: "Telefon",
              children: sessionState.expertPhone,
            },
          ]}
          size="small"
        />

        <Badge count="Danışan" color="#01c897" className="mt-5">
          <Button
            onClick={() =>
              navigate(`/user-detail/${sessionState.client.clientId}`)
            }
            className="sesion-rounded "
          >
            {sessionState.client.fullName || sessionState.client.nickname}
          </Button>
        </Badge>
        <Descriptions
          className="mt-3"
          bordered
          column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
          layout={window.innerWidth < 1000 ? "vertical" : "horizontal"}
          items={[
            {
              key: "0",
              label: "ID",
              children: (
                <Row>
                  {sessionState.client.clientId}
                  <Button type="primary" size="small" className="ml-2">
                    <CopyOutlined
                      classID="mr-3"
                      onClick={() => {
                        copyToClipboard(sessionState.client.clientId);
                        message.success("ID kopyalandı.");
                      }}
                    />
                  </Button>
                </Row>
              ),
            },
            {
              key: "1",
              label: "İsim Soyisim",
              children:
                sessionState.client.fullName || sessionState.client.nickname,
            },
            {
              key: "2",
              label: "Telefon",
              children: sessionState.client.phone,
            },
          ]}
          size="small"
        />
      </Card>

      <Card title="Seans Bilgileri">
        <Descriptions
          className="mt-3 col-12"
          bordered
          column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
          // layout={window.innerWidth < 1000 ? "vertical" : "horizontal"}
          items={[
            {
              key: "1",
              label: "Seans Tarihi",
              children: dayjs(sessionState.sessionTime).format(
                "DD-MM-YYYY HH:mm"
              ),
            },
            {
              key: "2",
              label: "Seans Süresi",
              children: `${sessionState.sessionDurationInMinutes} dakika`,
            },

            {
              key: "23",
              label: "Seans Durumu",
              children: constants.sessionTypes[sessionState.sessionType],
            },
            {
              key: "3",
              label: "Ücretsiz Ön Görüşme",
              children: sessionState.freePreviewSession ? "Evet" : "Hayır",
            },
            {
              key: "4",
              label: "Seans Bitmeden Görüşme Kapatalıldı",
              children: sessionState.closedBeforeFinish ? "Evet" : "Hayır",
            },
          ]}
          size="small"
        />
      </Card>
    </>
  );
};
const SessionDetail = () => {
  const [loading, setLoading] = useState(true);

  const { sessionId } = useParams();
  const dispatch = useDispatch();
  const getSessionAll = async () => {
    try {
      const response = await SessionService.getSession(sessionId);
      dispatch(setSessionDetail(response.data.data));
    } catch (error) {
      message.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSessionAll();
  }, []);
  return (
    <>
      <DetailHeader actionComponent={<ActionComponent />} />
      {loading ? (
        <Skeleton active />
      ) : (
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Görüşme Detay",
              children: <SessionConversationDetail />,
            },
            {
              key: "2",
              label: "Seans Düzenle",
              children: <SessionForm />,
            },
          ]}
        />
      )}
    </>
  );
};
export default SessionDetail;
