import React, { useState } from "react";
import { Card, Switch, Form, Button, Tabs, message } from "antd";
import MaskedInput from "antd-mask-input";
import { OsUpdateService } from "../../api";
const VersionController = ({ type, form }) => {
  return (
    <div className="row">
      <Form.Item
        label="Son Versiyon"
        className="col-12 col-md-12 col-lg-4"
        name={"lastVersion"}
      >
        <MaskedInput
          placeholder="Versiyon Yazınız"
          size="large"
          name="lastVersion"
          className="mask-antdinput"
          mask={[
            {
              mask: "0\\.0\\.0",
              lazy: false,
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Kabul Edilebilir Min. Versiyon"
        className="col-12 col-md-12 col-lg-4"
        name={"minimumAcceptableVersion"}
      >
        <MaskedInput
          placeholder="Versiyon Yazınız"
          size="large"
          name="minimumAcceptableVersion"
          className="mask-antdinput"
          mask={[
            {
              mask: "0\\.0\\.0",
              lazy: false,
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Min. İşletim Sistemi Versiyon"
        className="col-12 col-md-12 col-lg-4"
        name={"minimumOsVersion"}
      >
        <MaskedInput
          placeholder="Versiyon Yazınız"
          size="large"
          name="minimumOsVersion"
          className="mask-antdinput"
          mask={[
            {
              mask: "0\\.0",
              lazy: false,
            },
          ]}
        />
      </Form.Item>
    </div>
  );
};
export default function Settings() {
  const [form] = Form.useForm();
  const [osType, setOsType] = useState("IOS");

  const onChangeMode = (value) => {
    value = value ? "dark" : "default";
    localStorage.setItem("theme", value);
  };
  const onFinish = async (values) => {
    values.osType = osType;
    values.appType = "CLIENT_APP";

    try {
      onChangeMode(values?.darkModeIsActive);

      const { minimumAcceptableVersion, minimumOsVersion, lastVersion } =
        values;
      const isValidVersionService =
        minimumAcceptableVersion && minimumOsVersion && lastVersion;
      if (isValidVersionService) {
        delete values.darkModeIsActive;
        const response = await OsUpdateService.forceUpdate(values);
      }

      message.success("İşlem Başarılı!");
    } catch (error) {
      console.log(error);
      message.error(JSON.stringify(error));
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <Form onFinish={onFinish} layout="vertical">
      <Card title="Tema Ayarları">
        <div className="row">
          <Form.Item
            label="Gece Modu"
            name={"darkModeIsActive"}
            valuePropName="checked"
            className="col-12 col-md-6 col-lg-2"
          >
            <Switch size="large" name={"darkModeIsActive"} />
          </Form.Item>
        </div>
      </Card>
      <Card title="Mobile Versiyon Kontrolü" className="mt-3">
        <Tabs
          defaultActiveKey="1"
          onChange={(value) => setOsType(value)}
          items={[
            {
              key: "IOS",
              label: "IOS",
              children: <VersionController type={"IOS"} form={form} />,
            },
            {
              key: "ANDROID",
              label: "Android",
              children: <VersionController type={"ANDROID"} form={form} />,
            },
          ]}
        />
      </Card>
      <Form.Item className="w-100 mt-3">
        <Button htmlType="submit" className="w-100" type="primary">
          Güncelle
        </Button>
      </Form.Item>
    </Form>
  );
}
