import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

import { Tooltip } from "antd";

import ShadowBack from "../ShadowBack";

import routes from "../../routes";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    if (typeof this.mm === "function") {
      this.mm("dispose");
    }
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: window.location.pathname,
      isShowBack: false,
      theme: localStorage.getItem("theme"),
    };
  }
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    const toggleShowBack = this.setToggleShoBack;

    function toggleFunc() {
      toggleShowBack();
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  handleItemClick = (item) => {
    this.setState({ activeTab: item.to });
  };
  setToggleShoBack = () => {
    this.setState({ isShowBack: !this.state.isShowBack });
  };
  closeModal = () => {
    this.setState({ isShowBack: !this.state.isShowBack });
    var aaa = document.querySelector("#main-wrapper");
    aaa.classList.toggle("menu-toggle");
  };

  render() {
    const isMobile = window.innerWidth <= 600;
    const sideBars = routes
      .filter((item) => item?.icon)
      .map((item) => ({
        to: `/${item.path}`,
        text: item.name,
        icon: item.icon,
      }));

    return (
      <>
        <div className="deznav" data-theme={this.state.theme}>
          <PerfectScrollbar className="deznav-scroll">
            <MM className="metismenu" id="menu">
              {sideBars.map((item, key) => (
                <li
                  onClick={() => this.handleItemClick(item)}
                  key={key}
                  className={
                    this.state.activeTab === item.to
                      ? "active-sidebar-item"
                      : ""
                  }
                >
                  <Link to={item.to} className="ai-icon">
                    <Tooltip zIndex={3000} placement="right" title={item.text}>
                      {item.icon}
                    </Tooltip>
                    <span className="nav-text ml-3">{item.text}</span>
                  </Link>
                </li>
              ))}
            </MM>
          </PerfectScrollbar>
        </div>
        {this.state.isShowBack && isMobile && (
          <ShadowBack
            onClick={() => {
              this.closeModal();
            }}
          />
        )}
      </>
    );
  }
}

export default SideBar;
