import React, { useState } from "react";
import { Affix, Form, Button, Select, Card, Input, TextArea } from "antd";
import { ContentService } from "../../../../api";
export default function ContentDetail() {
  return (
    <Card className="mb-3 " title="İçerik Detay">
      <div className="row">
        <Form.Item
          label="Başlık"
          className="col-12 col-md-4 col-lg-4"
          name={"title"}
          rules={[
            {
              required: true,
              message: "Lütfen Başlık alanını doldurun.",
            },
          ]}
        >
          <Input placeholder="Başlık Yazınız" size="large" name="title" />
        </Form.Item>

        <Form.Item
          label="Dil"
          className="col-12 col-md-6 col-lg-4"
          name={"language"}
          rules={[
            {
              required: true,
              message: "Lütfen Dil alanını doldurun.",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            name={"language"}
            placeholder="Dil Seçiniz"
            options={[
              {
                value: "TR",
                label: (
                  <div
                    className="d-flex items-center justify-center"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <img
                      width={20}
                      height={15}
                      style={{
                        objectFit: "cover",
                        marginRight: 5,
                      }}
                      src="https://media.istockphoto.com/id/472330089/tr/vekt%C3%B6r/red-flag-of-turkey-with-white-symbol.jpg?s=612x612&w=0&k=20&c=1URrZ51FAoNb5O-vULrE6YeGZ3mJP3ck9T7Nrxeh58M="
                      alt="tr-flag"
                    />
                    <div>Türkçe</div>
                  </div>
                ),
              },
              {
                value: "EN",
                label: (
                  <div
                    className="d-flex items-center justify-center"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <img
                      width={20}
                      height={15}
                      style={{
                        objectFit: "cover",
                        marginRight: 5,
                      }}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/640px-Flag_of_the_United_Kingdom_%283-5%29.svg.png"
                      alt="en-flag"
                    />
                    <div>İngilizce</div>
                  </div>
                ),
              },
            ]}
            size="large"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </div>
      <div className="row">
        <Form.Item
          label="Açıklama"
          className="col-12 col-md-4 col-lg-4"
          name={"summary"}
          rules={[
            {
              required: true,
              message: "Lütfen Açıklama alanını doldurun.",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Açıklama Yazınız"
            size="large"
            name="summary"
          />
        </Form.Item>
        <Form.Item
          label="Arka Plan URL"
          className="col-12 col-md-4 col-lg-4"
          name={"thumbnailUrl"}
          rules={[
            {
              required: true,
              message: "Lütfen URL alanını doldurun.",
            },
          ]}
        >
          <Input placeholder="URL Yazınız" size="large" name="thumbnailUrl" />
        </Form.Item>
      </div>
    </Card>
  );
}
